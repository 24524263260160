import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import HomeIcon from "@mui/icons-material/Home";
import UsedBikeList from "../UsedBike/UsedBikeList";
import UsedBike from "../UsedBike/UsedBike";
import BikeServiceBooking from "../Booking/BikeServiceBooking";
import CarWashBooking from "../Booking/CarWashBooking";
import City from "../City/City";
import Worker from "../Worker/Worker";
import Brand from "../Brand/Brand";
import Operator from "../Operator/Operator";
import Workshop from "../Workshop/Workshop";
import BikeModel from "../BikeModel/BikeModel";
import CarModel from "../CarModel/CarModel";
import NewBike from "../NewBike/NewBike";
import logo from "./images/blue-bike-logo2.png";
import styles from "./Header.module.css";
import Admin from "../Admin/Admin";
import userImage from "./images/user.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { API_HOST_URL } from "../Constant/Constant";
import BikeServiceBookingList from "../Booking/BikeServiceBookingList";
import CarWashBookingList from "../Booking/CarWashBookingList";
import CarWashStatusUpdate from "../CarWash/CarWashStatusUpdate";
import BikeServicingStatusUpdate from "../BikeServicing/BikeServicingStatusUpdate";
import NewBikeList from "../NewBike/NewBikeList";
import WorkshopList from "../Workshop/WorkshopList";
import StatusUpdate from "../Status/StatusUpdate";
import ReviewActions from "../Review/ReviewActions";
import UsedBikes from "../Customer/UsedBikes";
import UserHome from "../HomePage/UserHome";

const pages = [
  {
    id: "buy_bike",
    name: "Buy Bike",
  },
  {
    id: "sell_bike",
    name: "Sell Bike",
  },
  {
    id: "buy_used_bike",
    name: "Buy Used Bike",
  },
  {
    id: "bike_servicing",
    name: "Bike Servicing",
  },
  {
    id: "car_wash",
    name: "Car Wash",
  },
  {
    id: "workshop_list",
    name: "Workshops",
  },
  {
    id: "my_bikes",
    name: "My Bikes",
  },
];

export function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isBuyBikeSelected, setIsBuyBikeSelected] = React.useState(false);
  const [isBuyUsedBikeSelected, setIsBuyUsedBikeSelected] =
    React.useState(false);
  const [isSellBikeSelected, setIsSellBikeSelected] = React.useState(false);
  const [isBikeServiceSelected, setIsBikeServiceSelected] =
    React.useState(false);
  const [isCarWashSelected, setIsCarWashSelected] = React.useState(false);
  const [isCitySelected, setIsCitySelected] = React.useState(false);
  const [isAdminSelected, setIsAdminSelected] = React.useState(false);
  const [isBrandSelected, setIsBrandSelected] = React.useState(false);
  const [isBikeModelSelected, setIsBikeModelSelected] = React.useState(false);
  const [isCarModelSelected, setIsCarModelSelected] = React.useState(false);
  const [isNewBikeSelected, setIsNewBikeSelected] = React.useState(false);
  const [isOperatorSelected, setIsOperatorSelected] = React.useState(false);
  const [isWorkerSelected, setIsWorkerSelected] = React.useState(false);
  const [isWorkshopSelected, setIsWorkshopSelected] = React.useState(false);
  const [isCarWashBookingListSelected, setIsCarWashBookingListSelected] =
    React.useState(false);
  const [isUserHomeSelected, setIsUserHomeSelected] = React.useState(true);
  const [
    isBikeServiceBookingListSelected,
    setIsBikeServiceBookingListSelected,
  ] = React.useState(false);
  const [isCarWashStatusUpdateSelected, setIsCarWashStatusUpdateSelected] =
    React.useState(false);
  const [
    isBikeServicingStatusUpdateSelected,
    setIsBikeServicingStatusUpdateSelected,
  ] = React.useState(false);
  const [isWorkshopListSelected, setIsWorkshopListSelected] =
    React.useState(false);
  const [isStatusUpdate, setIsStatusUpdate] = React.useState(false);
  const [isReviewSelected, setIsReviewSelected] = React.useState(false);
  const [isMyBikesSelected, setIsMyBikesSelected] = React.useState(false);

  const navigate = useNavigate();

  const userName = localStorage.getItem("name").toLowerCase();
  const userRole = localStorage.getItem("role");
  const userId = localStorage.getItem("userId");

  const token = localStorage.getItem("token");
  const carWash = localStorage.getItem("carWash");
  const bikeService = localStorage.getItem("bikeService");

  const setterMap = {};
  setterMap["Buy Bike"] = setIsBuyBikeSelected;
  setterMap["Sell Bike"] = setIsSellBikeSelected;
  setterMap["Buy Used Bike"] = setIsBuyUsedBikeSelected;
  setterMap["Bike Servicing"] = setIsBikeServiceSelected;
  setterMap["Car Wash"] = setIsCarWashSelected;
  setterMap["City"] = setIsCitySelected;
  setterMap["Admin"] = setIsAdminSelected;
  setterMap["Brand"] = setIsBrandSelected;
  setterMap["Bike Model"] = setIsBikeModelSelected;
  setterMap["Car Model"] = setIsCarModelSelected;
  setterMap["New Bike"] = setIsNewBikeSelected;
  setterMap["Operator"] = setIsOperatorSelected;
  setterMap["Worker"] = setIsWorkerSelected;
  setterMap["Workshop"] = setIsWorkshopSelected;
  setterMap["Car Wash Booking List"] = setIsCarWashBookingListSelected;
  setterMap["Bike Service Booking List"] = setIsBikeServiceBookingListSelected;
  setterMap["Car Wash Status Update"] = setIsCarWashStatusUpdateSelected;
  setterMap["Bike Servicing Status Update"] =
    setIsBikeServicingStatusUpdateSelected;
  setterMap["Workshops"] = setIsWorkshopListSelected;
  setterMap["Status Update"] = setIsStatusUpdate;
  setterMap["Review"] = setIsReviewSelected;
  setterMap["My Bikes"] = setIsMyBikesSelected;
  setterMap["Home"] = setIsUserHomeSelected;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (userId === null || userId === "") {
      navigate("/login");
    }

    if (carWash === "true") {
      setIsCarWashSelected(true);
      localStorage.setItem("carWash", false);
    } else if (bikeService === "true") {
      setIsBikeServiceSelected(true);
      localStorage.setItem("bikeService", false);
    }
  }, [userId, navigate, carWash, bikeService]);

  const handleLogout = (e) => {
    e.preventDefault();
    const data = {
      userId: userId,
    };

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    axios
      .post(API_HOST_URL + "/auth/logout", data, options)
      .then((response) => {
        localStorage.setItem("token", "");
        localStorage.setItem("userId", "");
        localStorage.setItem("role", "");
        localStorage.setItem("name", "");
        navigate("/login");
      })
      .catch((error) => {
        navigate("/login");
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (e) => {
    // alert(e.currentTarget.innerText);
    setPage(e.currentTarget.innerText);
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const setPage = (page) => {
    setIsBikeServiceSelected(false);
    setIsBuyBikeSelected(false);
    setIsBuyUsedBikeSelected(false);
    setIsSellBikeSelected(false);
    setIsCarWashSelected(false);
    setIsAdminSelected(false);
    setIsBrandSelected(false);
    setIsBikeModelSelected(false);
    setIsCarModelSelected(false);
    setIsCitySelected(false);
    setIsNewBikeSelected(false);
    setIsOperatorSelected(false);
    setIsWorkerSelected(false);
    setIsWorkshopSelected(false);
    setIsCarWashBookingListSelected(false);
    setIsBikeServiceBookingListSelected(false);
    setIsCarWashStatusUpdateSelected(false);
    setIsBikeServicingStatusUpdateSelected(false);
    setIsWorkshopListSelected(false);
    setIsStatusUpdate(false);
    setIsReviewSelected(false);
    setIsMyBikesSelected(false);
    setIsUserHomeSelected(false);

    setterMap[page](true);
  };

  return (
    <div>
      <AppBar position="static" style={{ backgroundColor: "skyblue" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <a href="/home">
              <HomeIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
            </a>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.id} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <div>
              <a href="/home">
                {" "}
                <img
                  src={logo}
                  className="logo_img"
                  alt="logo"
                  style={{
                    width: "100px",
                    height: "40px",
                    verticalAlign: "bottom",
                  }}
                />
              </a>
            </div>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            ></Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page.id}
                  onClick={setPage.bind(this, page.name)}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>

            <div>
              {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{ color: "white" }}
                >
                  Reg
                </Button>
              )}
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {userRole === "ADMIN" && (
                <MenuItem key="Brand" onClick={setPage.bind(this, "Brand")}>
                  <Typography textAlign="center">Brand</Typography>
                </MenuItem>
              )}
              {userRole === "ADMIN" && (
                <MenuItem
                  key="Bike Model"
                  onClick={setPage.bind(this, "Bike Model")}
                >
                  <Typography textAlign="center">Bike Model</Typography>
                </MenuItem>
              )}
              {userRole === "ADMIN" && (
                <MenuItem
                  key="Car Model"
                  onClick={setPage.bind(this, "Car Model")}
                >
                  <Typography textAlign="center">Car Model</Typography>
                </MenuItem>
              )}
              {userRole === "ADMIN" && (
                <MenuItem
                  key="Operator"
                  onClick={setPage.bind(this, "Operator")}
                >
                  <Typography textAlign="center">Operator</Typography>
                </MenuItem>
              )}
              {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <MenuItem key="Worker" onClick={setPage.bind(this, "Worker")}>
                  <Typography textAlign="center">Worker</Typography>
                </MenuItem>
              )}
              {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <MenuItem
                  key="Workshop"
                  onClick={setPage.bind(this, "Workshop")}
                >
                  <Typography textAlign="center">Workshop</Typography>
                </MenuItem>
              )}
              {userRole === "ADMIN" && (
                <MenuItem key="Admin" onClick={setPage.bind(this, "Admin")}>
                  <Typography textAlign="center">Admin</Typography>
                </MenuItem>
              )}
              {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <MenuItem
                  key="New Bike"
                  onClick={setPage.bind(this, "New Bike")}
                >
                  <Typography textAlign="center">New Bike</Typography>
                </MenuItem>
              )}
              {userRole === "ADMIN" && (
                <MenuItem key="City" onClick={setPage.bind(this, "City")}>
                  <Typography textAlign="center">City</Typography>
                </MenuItem>
              )}
              {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <MenuItem
                  key="Car Wash Booking List"
                  onClick={setPage.bind(this, "Car Wash Booking List")}
                >
                  <Typography textAlign="center">
                    Car Wash Booking List
                  </Typography>
                </MenuItem>
              )}
              {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <MenuItem
                  key="Bike Service Booking List"
                  onClick={setPage.bind(this, "Bike Service Booking List")}
                >
                  <Typography textAlign="center">
                    Bike Service Booking List
                  </Typography>
                </MenuItem>
              )}
              {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <MenuItem
                  key="Car Wash Status Update"
                  onClick={setPage.bind(this, "Car Wash Status Update")}
                >
                  <Typography textAlign="center">
                    Car Wash Status Update
                  </Typography>
                </MenuItem>
              )}
              {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <MenuItem
                  key="Bike Servicing Status Update"
                  onClick={setPage.bind(this, "Bike Servicing Status Update")}
                >
                  <Typography textAlign="center">
                    Bike Servicing Status Update
                  </Typography>
                </MenuItem>
              )}
              {(userRole === "ADMIN" || userRole === "OPERATOR") && (
                <MenuItem
                  key="Status Update"
                  onClick={setPage.bind(this, "Status Update")}
                >
                  <Typography textAlign="center">Status Update</Typography>
                </MenuItem>
              )}
              {userRole === "ADMIN" && (
                <MenuItem key="Review" onClick={setPage.bind(this, "Review")}>
                  <Typography textAlign="center">Review Actions</Typography>
                </MenuItem>
              )}
            </Menu>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <span className={styles.welcome}>{userName} </span>
                  <Avatar alt={userName} src={userImage} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {/* {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))} */}
                <MenuItem key="Profile" onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <MenuItem key="Logout" onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <div className={styles.content}>
        {isBuyBikeSelected && <NewBikeList />}
        {isSellBikeSelected && <UsedBike />}
        {isBuyUsedBikeSelected && <UsedBikeList />}
        {isBikeServiceSelected && <BikeServiceBooking />}
        {isCarWashSelected && <CarWashBooking />}
        {isCitySelected && <City />}
        {isWorkerSelected && <Worker />}
        {isBrandSelected && <Brand />}
        {isOperatorSelected && <Operator />}
        {isWorkshopSelected && <Workshop />}
        {isBikeModelSelected && <BikeModel />}
        {isCarModelSelected && <CarModel />}
        {isNewBikeSelected && <NewBike />}
        {isAdminSelected && <Admin />}
        {isCarWashBookingListSelected && <CarWashBookingList />}
        {isBikeServiceBookingListSelected && <BikeServiceBookingList />}
        {isCarWashStatusUpdateSelected && <CarWashStatusUpdate />}
        {isBikeServicingStatusUpdateSelected && <BikeServicingStatusUpdate />}
        {isWorkshopListSelected && <WorkshopList />}
        {isStatusUpdate && <StatusUpdate />}
        {isReviewSelected && <ReviewActions />}
        {isMyBikesSelected && <UsedBikes />}
        {isUserHomeSelected && <UserHome />}
      </div>
    </div>
  );
}
