import { useEffect, useState } from "react";
import { API_HOST_URL } from "../Constant/Constant";
import styles from "./NewBike.module.css";
import { Footer } from "../Footer/Footer";
import NewBikeDetail from "./NewBikeDetail";
import mainStyles from "../Main.module.css";
import bikesImage from "./images/bikes.png";
import AppPagination from "../Pagination/AppPagination";
import { Button } from "@mui/material";
import UpdateNewBike from "./UpdateNewBike";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function NewBikeList() {
  const [bikes, setBikes] = useState([]);
  const [showBikeDetail, setShowBikeDetail] = useState(false);
  const [showBikeList, setShowBikeList] = useState(true);
  const [showBikeUpdate, setShowBikeUpdate] = useState(false);
  const [bikeId, setBikeId] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [bikeNames, setBikeNames] = useState([]);
  const [model, setModel] = useState("");
  const [open, setOpen] = useState(false);

  const openConfirmationDialogBox = (e, bikeId) => {
    setBikeId(bikeId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let role = localStorage.getItem("role");

  useEffect(() => {
    fetch(API_HOST_URL + "/bikeModels/names")
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setBikeNames(res.bikeModelNames);
      });

    loadBikes();
  }, [page, model]);

  const loadBikes = () => {
    fetch(API_HOST_URL + "/newBikes?model=" + model + "&page=" + page)
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setBikes(res.bikes);
        setTotalPages(res.totalPages);
      });
  };

  const handleUpdate = (e, bike) => {
    setBikeId(bike);
    setShowBikeUpdate(true);
    setShowBikeList(false);
    setShowBikeDetail(false);
  };

  const handleDelete = () => {
    fetch(API_HOST_URL + "/newBikes/" + bikeId, {
      method: "DELETE",
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setOpen(false);
        loadBikes();
        window.scroll(0, 0);
      });
  };

  const handleViewMore = (e, bike) => {
    setBikeId(bike);
    setShowBikeDetail(true);
    setShowBikeList(false);
  };

  const defaultProps = {
    options: bikeNames,
    getOptionLabel: (option) => option.name,
  };

  const handleBikeChange = (modelObj) => {
    setModel(modelObj.id);
  };

  return (
    <div>
      {showBikeList && (
        <div>
          <div className={styles.headingImageDiv}>
            <img src={bikesImage} alt="Bikes" className={styles.bigImage} />
          </div>
          <div className={styles.bikeListcontainer}>
            <div className={mainStyles.header}>New Bikes</div>

            <div className={styles.mainContainer}>
              <Autocomplete
                onChange={(event, value) => handleBikeChange(value)}
                disablePortal
                {...defaultProps}
                sx={{
                  width: 300,
                  backgroundColor: "white",
                  borderRadius: "20px",
                  height: "40px",
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Search Bike" />
                )}
              />
            </div>
            {bikes.map((bike) => (
              <div>
                <div className={styles.propContainer}>
                  <div className={styles.bikeDetailContainer}>
                    <div className={styles.bikeImageDiv}>
                      <img
                        onClick={(e) => handleViewMore(e, bike.bikeId)}
                        className={styles.bikeImage}
                        src={bike.imageUrl}
                        alt="bikeImage"
                      />
                    </div>
                    <div className={styles.bikeDetailDiv}>
                      <div className={styles.bikeDetail}>
                        <div className={styles.brand}>
                          {bike.brand} {bike.model}
                        </div>
                        <div className={styles.modelYear}>
                          {bike.engineCapacity}{" "}
                        </div>
                        <div className={styles.price}>
                          {" "}
                          {bike.exShowroomPrice}/-
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.linkContainer}>
                  <Button
                    onClick={(e) => handleViewMore(e, bike.bikeId)}
                    className={styles.button}
                  >
                    View
                  </Button>
                  {role === "ADMIN" && (
                    <>
                      <Button
                        onClick={(e) => handleUpdate(e, bike.bikeId)}
                        className={styles.button}
                      >
                        Update
                      </Button>
                      <Button
                        onClick={(e) =>
                          openConfirmationDialogBox(e, bike.bikeId)
                        }
                        className={`${styles.button} ${styles.delete}`}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </div>
              </div>
            ))}
            <AppPagination setPage={setPage} totalPages={totalPages} />
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete Bike?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this bike?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={handleDelete}
                  autoFocus
                  className={`${styles.button} ${styles.delete}`}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          <Footer />
        </div>
      )}
      {showBikeDetail && <NewBikeDetail bikeId={bikeId} />}
      {showBikeUpdate && <UpdateNewBike bikeId={bikeId} />}
    </div>
  );
}
