import { useEffect, useState } from "react"
import styles from "./booking.module.css"
import { API_HOST_URL } from "../Constant/Constant";
import CarWashBookingDetail from "./CarWashBookingDetail";
import CarWash from "../CarWash/CarWash";


export default function CarWashBookingList() {

    const [carWashBookings, setCarWashBookings] = useState([]);

    const [showCarWashBookingList, setShowCarWashBookingList] = useState(false);
    const [showCarWash, setShowCarWash] = useState(false);
    const [showCarWashBookingDetail, setShowCarWashDetail] = useState(false);
    const [bookingId, setBookingId] = useState();



    useEffect(() => {
        fetch(API_HOST_URL + "/bookings/carWash")
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setCarWashBookings(res);
                setShowCarWashBookingList(true);
            });
    }, []);

    const showCarWashPage = (bookingId) => {
        setBookingId(bookingId);
        setShowCarWash(true);
        setShowCarWashBookingList(false);
        setShowCarWashDetail(false);


    }

    const showCarWashDetailPage = (bookingId) => {
        setBookingId(bookingId);
        setShowCarWashDetail(true);
        setShowCarWashBookingList(false);
        setShowCarWash(false);

    }

    return (
        <div>
            {showCarWashBookingList && <div>
                <header className={styles.header}>Car Wash Booking List</header>
                <div className={styles.headerRow}>
                    <div className={styles.fieldHeader}>Booking #</div>
                    <div className={styles.fieldHeader}>Customer Name</div>
                    <div className={styles.fieldHeader}>Model</div>
                    <div className={`${styles.fieldHeader} ${styles.serviceDateHeader}`}>Service Date</div>
                    <div className={styles.fieldHeader}>Reg. Number</div>
                    <div className={styles.fieldHeader}>Status</div>
                </div>
                <div className={styles.fieldRow}>
                    {carWashBookings.map((booking) => {
                        return (
                            <div key={booking.id}>
                                <div className={styles.field}>{booking.bookingNumber}</div>
                                <div className={styles.field}>{booking.customerFirstName} {booking.customerLastName}</div>
                                <div className={styles.field}>{booking.brand} {booking.carModel}</div>
                                <div className={`${styles.field} ${styles.serviceDate}`}>{booking.scheduledAt}</div>
                                <div className={styles.field}>{booking.registrationNumber}</div>
                                <div className={styles.field}>BOOKED</div>
                                {/* <div className={styles.field}><a href={`carWashBookingDetail?bookingId=${booking.bookingId}`}>View More</a></div>
                                <div className={styles.field}><a href={`carWash?bookingId=${booking.bookingId}`}>Create Car Wash</a></div> */}

                                <div className={styles.field}><span onClick={(e) => showCarWashDetailPage(booking.bookingId)}>View More</span></div>
                                <div className={styles.field}><span onClick={(e) => showCarWashPage(booking.bookingId)}>Create Car Wash</span></div>
                            </div>
                        )
                    })}
                </div>
            </div>}
            {showCarWashBookingDetail && <CarWashBookingDetail bookingId={bookingId} />}
            {showCarWash && <CarWash bookingId={bookingId} />}
        </div>
    )
}