import { useEffect, useState } from "react";
import { Footer } from "../Footer/Footer";
import { HeaderPlain } from "../Header/HeaderPlain";
import { API_HOST_URL } from "../Constant/Constant";
import styles from "./Review.module.css";
import AccountCircle from '@mui/icons-material/AccountCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StarSharp from '@mui/icons-material/StarSharp';
import { TextField, Button } from "@mui/material";
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";

export default function ReviewCarWash() {
    let carWashId = (new URLSearchParams(window.location.search)).get("carWashId");
    let customerId = (new URLSearchParams(window.location.search)).get("customerId");
    const [carWash, setCarWash] = useState();
    const [reviewText, setReviewText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [ratingTemp, setRating] = useState(3);

    useEffect(() => {


        fetch(API_HOST_URL + "/carWash/byCarWashId/" + carWashId)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setCarWash(res);
            });
    });

    const validateForm = () => {
        let isValid = true;
        if (reviewText === '') {
            isValid = false;
        }
        return isValid;
    }

    const onSuccessfulSave = () => {
        setIsSaved(true);
        setIsLoading(false);
        setHasError(false);
    }

    const onFailedSave = () => {
        setIsLoading(false);
        setIsSaved(false);
        setHasError(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        let rating = ratingTemp + 1;
        const review = { rating, reviewText, carWashId, customerId };

        if (validateForm()) {

            fetch(API_HOST_URL + "/reviews/carWash", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(review)
            }).then((res) => {
                onSuccessfulSave();

            }).catch((error) => {
                onFailedSave();
            });
        }
        else {
            onFailedSave();
        }


    }

    return (
        isLoading ? <div className={mainStyles.loadingContainer}><img src={loadingImage} alt="loading" /></div> :
            <div className={styles.rootContainer}>
                <HeaderPlain />
                <div className={mainStyles.header}>Review Car Wash</div>
                <div className={styles.mainContainer}>
                    {carWash && <div className={styles.customerDetailContainer}>
                        <div className={styles.customerDetailInnerDiv}>
                            <div className={styles.profilePicDiv}>
                                <AccountCircle sx={{ fontSize: 100 }} />
                            </div>
                            <div className={styles.customerDetailDiv}>
                                <div className={styles.name}>{carWash.customerFullName}</div>
                                <div className={styles.locationIcon}><LocationOnIcon fontSize="large" /> {carWash.address}, {carWash.city}</div>
                            </div>


                        </div>
                    </div>}
                    <div>
                        <div className={styles.starContainer}>{[...Array(5)].map((item, i) => <div className={(i <= ratingTemp ? styles.starActive : styles.starInactive)} key={i} onClick={(e) => setRating(i)}><StarSharp sx={{ fontSize: 60 }} /></div>)}</div>
                        <div className={styles.reviewContainer}>
                            <TextField multiline rows={5} className={styles.reviewText} onBlur={(e) => setReviewText(e.target.value)} placeholder="Write your review here"></TextField>
                        </div>
                        <div className={styles.btnContainer}>
                            <Button className={styles.saveBtn} onClick={handleSubmit} variant="contained" > Submit</Button>
                        </div>
                        <div className={styles.successContainer}>
                            {isSaved && <div className={mainStyles.success}>Review submitted successfully</div>}
                            {hasError && <div className={mainStyles.error}>Error submitting review</div>}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
    );
}