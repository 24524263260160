import { useState, useEffect } from "react";
import CustomSlider from "../CustomSlider/custom.slider";
import { styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import styles from "./Workshop.module.css"
import { API_HOST_URL } from "../Constant/Constant";
import { HeaderPlain } from "../Header/HeaderPlain";
import mainStyles from "../Main.module.css";


import React from "react";


const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Tab = styled(BaseTab)`
    font-family: 'IBM Plex Sans', sans-serif;
    color: #fff;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 600;
    background-color: transparent;
    width: 100%;
    padding: 10px 12px;
    margin: 6px;
    border: none;
    border-radius: 7px;
    display: flex;
    justify-content: center;
  
    &:hover {
      background-color: ${blue[400]};
    }
  
    &:focus {
      color: #fff;
      outline: 3px solid ${blue[200]};
    }
  
    &.${tabClasses.selected} {
      background-color: #fff;
      color: ${blue[600]};
    }
  
    &.${buttonClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;

const TabPanel = styled(BaseTabPanel)(
    ({ theme }) => `
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    padding: 20px 12px;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    border-radius: 12px;
    opacity: 0.6;
    `,
);

const TabsList = styled(BaseTabsList)(
    ({ theme }) => `
    min-width: 400px;
    background-color: ${blue[500]};
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
    `,
);
export default function WorkshopDetail({ workshopId }) {

    const [images, setImages] = useState([]);
    const [workshop, setWorkshop] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);




    useEffect(() => {
        if (localStorage.getItem("token")) {
            setIsLoggedIn(true);

        }

        fetch(API_HOST_URL + "/workshops/" + workshopId)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setWorkshop(res);
                setImages(res.images)
            });
    }, [workshopId]);

    return (
        <div>
            {/* {isLoggedIn && <Header />} */}
            {!isLoggedIn && <HeaderPlain />}
            {workshop && <header className={mainStyles.header}>{workshop.name} </header>}

            <div className="slider">

                {images && <CustomSlider>
                    {images.map((image) => {
                        return <img key={image.imageId} src={image.imageUrl} alt={image.imageNumber} />;
                    })}
                </CustomSlider>}

                <div className="workshopDetail">
                    {workshop && <Tabs defaultValue={0}>
                        <TabsList>
                            <Tab value={0}>Workshop details</Tab>
                            <Tab value={1}>Address Details</Tab>
                        </TabsList>

                        <TabPanel value={0}>
                            <div className="leftDiv">

                                <div><div className={styles.fieldName}>Name </div><div className={styles.fieldValue}>{workshop.name}</div></div>
                                <div><div className={styles.fieldName}>Owner Name </div><div className={styles.fieldValue}> {workshop.ownerName}</div></div>
                                <div><div className={styles.fieldName}>Phone 1 </div><div className={styles.fieldValue}>{workshop.phoneNumber}</div></div>
                                <div><div className={styles.fieldName}>Phone 2 </div><div className={styles.fieldValue}>{workshop.alternatePhoneNumber}</div></div>
                            </div>
                            <div className="rightDiv">

                                <div><div className={styles.fieldName}>Email  </div><div className={styles.fieldValue}>{workshop.email}</div></div>
                                <div><div className={styles.fieldName}>Experience </div><div className={styles.fieldValue}>{workshop.yearsOld} year(s)</div></div>
                            </div>

                        </TabPanel>
                        <TabPanel value={1}>
                            <div> <div><div className={styles.fieldName}>Shop # </div><div className={styles.fieldValue}>{workshop.shopNumber}</div></div>
                                <div><div className={styles.fieldName}>Address </div><div className={styles.fieldValue}> {workshop.address}</div></div>
                                <div><div className={styles.fieldName}>City  </div><div className={styles.fieldValue}>{workshop.city}</div></div>
                                <div><div className={styles.fieldName}>State  </div><div className={styles.fieldValue}>{workshop.state}</div></div>
                                <div><div className={styles.fieldName}>Zip Code </div><div className={styles.fieldValue}>{workshop.zipCode}</div></div>
                            </div>
                        </TabPanel>
                    </Tabs>}
                </div>
            </div>
        </div>
    )
}
