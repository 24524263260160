import { useEffect, useState } from "react";
import styles from "./Workshop.module.css";
import { API_HOST_URL } from "../Constant/Constant";
import workshopsImage from "./images/workshops.jpg";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarSharp from "@mui/icons-material/StarSharp";
import WorkshopDetail from "./WorkshopDetail";
import mainStyles from "../Main.module.css";
import AppPagination from "../Pagination/AppPagination";

export default function WorkshopList() {
  const [workshops, setWorkshops] = useState([]);
  const [showWorkshopDetail, setShowWorkshopDetail] = useState(false);
  const [showWorkshopContainer, setShowWorkshopContainer] = useState(false);
  const [showWorkshopList, setShowWorkshopList] = useState(true);
  const [workshopId, setWorkshopId] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    fetch(API_HOST_URL + "/workshops?page=" + page)
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setWorkshops(res.workshops);
        setTotalPages(res.totalPages);

        if (res.totalPages > 0) {
          setShowWorkshopContainer(true);
        }
      });
  }, [page]);

  const handleViewMore = (e, workshop) => {
    setWorkshopId(workshop);
    setShowWorkshopDetail(true);
    setShowWorkshopList(false);
  };

  return (
    <div>
      {showWorkshopList && (
        <div>
          <div className={styles.headingImageDiv}>
            <img src={workshopsImage} alt="Bikes" className={styles.bigImage} />
          </div>
          {showWorkshopContainer && (
            <div className={styles.workshopListcontainer}>
              <header className={mainStyles.header}>Workshops</header>
              {workshops.map((workshop) => (
                <div className={styles.propContainer}>
                  <div className={styles.workshopDetailContainer}>
                    <div className={styles.workshopImageDiv}>
                      <img
                        onClick={(e) => handleViewMore(e, workshop.workshopId)}
                        className={styles.workshopImage}
                        src={workshop.imageUrl}
                        alt="workshopImage"
                      />
                    </div>
                    <div className={styles.workshopDetailDiv}>
                      <div className={styles.workshopDetail}>
                        <div className={styles.name}>{workshop.name}</div>
                        <div className={styles.starContainer}>
                          {[...Array(workshop.rating)].map((item, i) => (
                            <div className={styles.starActive} key={i}>
                              <StarSharp />
                            </div>
                          ))}
                        </div>
                        <div className={styles.yearsOld}>
                          {workshop.yearsOld} year(s) old
                        </div>

                        <div>
                          <div className={styles.locationIcon}>
                            <LocationOnIcon fontSize="medium" />{" "}
                            {workshop.address}, {workshop.city}
                          </div>
                        </div>
                        <div className={styles.linkContainer}>
                          {" "}
                          <span
                            onClick={(e) =>
                              handleViewMore(e, workshop.workshopId)
                            }
                            className={styles.link}
                          >
                            View More{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <AppPagination totalPages={totalPages} setPage={setPage} />
            </div>
          )}
        </div>
      )}
      {showWorkshopDetail && <WorkshopDetail workshopId={workshopId} />}
    </div>
  );
}
