import { useEffect, useState } from "react";
import styles from "./customer.module.css";
import { API_HOST_URL } from "../Constant/Constant";
import UsedBikeDetail from "../UsedBike/UsedBikeDetail";
import bikesImage from "./images/bikes.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import mainStyles from "../Main.module.css";
import { Button } from "@mui/material";
import UpdateUsedBike from "../UsedBike/UpdateUsedBike";
import AppPagination from "../Pagination/AppPagination";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function UsedBikes() {
  const [bikes, setBikes] = useState([]);
  const [showBikeDetail, setShowBikeDetail] = useState(false);
  const [showBikeList, setShowBikeList] = useState(true);
  const [showUpdateBike, setShowUpdateBike] = useState(false);
  const [bikeId, setBikeId] = useState("");
  let customerId = localStorage.getItem("userId");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);

  const openConfirmationDialogBox = (e, bikeId) => {
    setBikeId(bikeId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadBikes(page);
  }, [page]);

  const loadBikes = () => {
    fetch(API_HOST_URL + "/usedBikes/customer/" + customerId + "?page=" + page)
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setBikes(res.usedBikes);
        setTotalPages(res.totalPages);
      });
  };

  const handleViewMore = (e, bike) => {
    setBikeId(bike);
    setShowBikeDetail(true);
    setShowBikeList(false);
    setShowUpdateBike(false);
  };

  const handleUpdate = (e, bike) => {
    setBikeId(bike);
    setShowBikeDetail(false);
    setShowBikeList(false);
    setShowUpdateBike(true);
  };

  const handleDelete = () => {
    fetch(API_HOST_URL + "/usedBikes/" + customerId + "/" + bikeId, {
      method: "DELETE",
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setOpen(false);
        loadBikes();
        window.scroll(0, 0);
      });
  };

  return (
    <div>
      {showBikeList && (
        <div>
          <div className={styles.headingImageDiv}>
            <img src={bikesImage} alt="Bikes" className={styles.bigImage} />
          </div>

          <div className={styles.bikeListcontainer}>
            <div className={mainStyles.header}>My Bikes</div>
            {bikes.map((bike) => (
              <div className={styles.propContainer}>
                <div className={styles.bikeDetailContainer}>
                  <div className={styles.bikeImageDiv}>
                    <img
                      onClick={(e) => handleViewMore(e, bike.bikeId)}
                      className={styles.bikeImage}
                      src={bike.imageUrl}
                      alt="left"
                    />
                  </div>
                  <div className={styles.bikeDetailDiv}>
                    <div className={styles.bikeDetail}>
                      <div className={styles.brand}>
                        {bike.brand} {bike.model}
                      </div>
                      <div className={styles.modelYear}>
                        {bike.modelYear} Model
                      </div>
                      <div className={styles.price}> {bike.price}/-</div>
                      <div>
                        <div className={styles.locationIcon}>
                          <LocationOnIcon fontSize="medium" />
                        </div>
                        <div className={styles.city}>
                          {bike.sellerAddress}, {bike.sellerCity}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.linkContainer}>
                    <Button
                      onClick={(e) => handleViewMore(e, bike.bikeId)}
                      className={styles.button}
                    >
                      View
                    </Button>
                    <Button
                      onClick={(e) => handleUpdate(e, bike.bikeId)}
                      className={styles.button}
                    >
                      Update
                    </Button>
                    <Button
                      onClick={(e) => openConfirmationDialogBox(e, bike.bikeId)}
                      className={`${styles.button} ${styles.delete}`}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            ))}
            <AppPagination setPage={setPage} totalPages={totalPages} />
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete Bike?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this bike?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={handleDelete}
                  autoFocus
                  className={`${styles.button} ${styles.delete}`}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      )}
      {showBikeDetail && <UsedBikeDetail bikeId={bikeId} />}
      {showUpdateBike && <UpdateUsedBike bikeId={bikeId} />}
    </div>
  );
}
