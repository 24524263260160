import React from 'react';
import styles from './LandingPage.module.css';
import bigImage from './images/main-bike.png'; // Import the image
import heroLogo from './images/hero-logo.webp';
import bajajLogo from './images/bajaj-logo.webp';
import royalEnfieldLogo from './images/royal-enfield-logo.webp';
import tvsLogo from './images/tvs-logo.webp';
import hondaLogo from './images/honda-logo.webp';
import yamahaLogo from './images/yamaha-logo.webp';
import suzukiLogo from './images/suzuki-logo.webp';
import ktmLogo from './images/ktm-logo.webp';
import harleyDavidsonLogo from './images/harley-davidson-logo.jpg';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

const LandingPage = () => {
    const userId = localStorage.getItem('userId');
    const navigate = useNavigate();

    const handleCarWash = (value) => {
        if (userId !== null && userId !== undefined) {
            localStorage.setItem('carWash', true);
            localStorage.setItem('bikeService', false);
            navigate('/home');
        }
        else {
            navigate('/login');
        }
    }

    const handleBikeService = (value) => {
        if (userId !== null && userId !== undefined) {
            localStorage.setItem('bikeService', true);
            localStorage.setItem('carWash', false);
            navigate('/home');
        }
        else {
            navigate('/login');
        }
    }

    const handleNewBike = (value) => {
        navigate('/newBikeListShort');
    }

    const handleUsedBike = (value) => {
        navigate('/usedBikeListShort');
    }


    return (
        <div className={styles.container}>

            <div className={styles.loginRegister}>
                <a href="/login" className={styles.loginLink} >
                    Log In
                </a>
            </div>
            <div className={styles.heading}>
                <div className={styles.heading1}>Chase Your Dream with</div>
                <div className={styles.heading2}>Blue Bike</div>
            </div>



            <div className={styles.headingImageDiv}>
                <img src={bigImage} alt="Bike" className={styles.bigImage} />

            </div>
            <div className={styles.containerDiv}>


                <div className={styles.buttonDiv} >
                    <Button className={styles.button} onClick={(e) => handleNewBike(e.target.value)} variant="standard" > New Bike</Button>
                </div>
                <div className={styles.buttonDiv}>
                    <Button className={styles.button} onClick={(e) => handleUsedBike(e.target.value)} variant="standard" > Used Bike</Button>
                </div>

                <div className={styles.buttonDiv}>
                    <Button className={styles.button} onClick={(e) => handleCarWash(e.target.value)} variant="standard" > Car Wash</Button>
                </div>
                <div className={styles.buttonDiv}>
                    <Button className={styles.button} onClick={(e) => handleBikeService(e.target.value)} variant="standard" > Bike Servicing</Button>
                </div>


            </div>
            <div className={styles.scrollableLogosContainer}>
                <img src={heroLogo} alt="Hero" className={styles.logo} />
                <img src={bajajLogo} alt="Bajaj" className={styles.logo} />
                <img src={royalEnfieldLogo} alt="Royal Enfield" className={styles.logo} />
                <img src={tvsLogo} alt="TVS" className={styles.logo} />
                <img src={hondaLogo} alt="Honda" className={styles.logo} />
                <img src={yamahaLogo} alt="Yamaha" className={styles.logo} />
                <img src={suzukiLogo} alt="Suzuki" className={styles.logo} />
                <img src={ktmLogo} alt="KTM" className={styles.logo} />
                <img src={harleyDavidsonLogo} alt="Harley Davidson" className={styles.logo} />
                {/* Add more logos as needed */}
            </div>
        </div>
    );
};

export default LandingPage;
