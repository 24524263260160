import styles from "./StatusUpdate.module.css"
import { useState } from "react";
import BikeServicingPickedUp from "../BikeServicing/BikeServicingPickedUp";
import BikeServicingDroppedAtWorkshop from "../BikeServicing/BikeServicingDroppedAtWorkshop";
import BikeServicingComplete from "../BikeServicing/BikeServicingComplete";
import BikeServicingCancelled from "../BikeServicing/BikeServicingCancelled";
import CarWashStart from "../CarWash/CarWashStart";
import CarWashComplete from "../CarWash/CarWashComplete";
import CarWashCancel from "../CarWash/CarWashCancel";
import mainStyles from "../Main.module.css";

export default function StatusUpdate() {

    const [showStatusUpdateForm, setShowStatusUpdateForm] = useState(true);
    const [showBikeServicePickedUpForm, setShowBikeServicePickedUpForm] = useState(false);
    const [showBikeServiceDroppedAtWorkshopForm, setShowBikeServiceDroppedAtWorkshopForm] = useState(false);
    const [showBikeServiceCompleteForm, setShowBikeServiceCompleteForm] = useState(false);
    const [showBikeServiceCancelledForm, setShowBikeServiceCancelledForm] = useState(false);

    const [showCarWashStartForm, setShowCarWashStartForm] = useState(false);
    const [showCarWashCompleteForm, setShowCarWashCompleteForm] = useState(false);
    const [showCarWashCancelForm, setShowCarWashCancelForm] = useState(false);

    const setterMap = {};
    setterMap['show_status_update_form'] = setShowStatusUpdateForm;
    setterMap['show_bike_service_pickup_form'] = setShowBikeServicePickedUpForm
    setterMap['show_bike_service_dropped_at_workshop_form'] = setShowBikeServiceDroppedAtWorkshopForm;
    setterMap['show_bike_service_complete_form'] = setShowBikeServiceCompleteForm;
    setterMap['show_bike_service_cancelled_form'] = setShowBikeServiceCancelledForm;

    setterMap['show_car_wash_start_form'] = setShowCarWashStartForm;
    setterMap['show_car_wash_complete_form'] = setShowCarWashCompleteForm;
    setterMap['show_car_wash_cancel_form'] = setShowCarWashCancelForm;


    const handleClick = (page) => {
        setterMap['show_status_update_form'](false);
        setterMap['show_bike_service_pickup_form'](false);
        setterMap['show_bike_service_dropped_at_workshop_form'](false);
        setterMap['show_bike_service_complete_form'](false);
        setterMap['show_bike_service_cancelled_form'](false);

        setterMap['show_car_wash_start_form'](false);
        setterMap['show_car_wash_complete_form'](false);
        setterMap['show_car_wash_cancel_form'](false);

        setterMap[page](true);
    }


    return (
        <div>
            {showStatusUpdateForm && <div className={styles.rootContainer}>
                <div className={styles.bikeServiceContainer}>
                    <h1 className={mainStyles.header}>Bike Servicing</h1>
                    <div className={styles.linkContainer} >Bike Service - Create</div>
                    <div className={styles.linkContainer}>Bike Service - Update</div>
                    <div className={styles.linkContainer} onClick={handleClick.bind(this, "show_bike_service_pickup_form")}>Bike Service - Pickup</div>
                    <div className={styles.linkContainer} onClick={handleClick.bind(this, "show_bike_service_dropped_at_workshop_form")} >Bike Service - Dropped at Workshop</div>
                    <div className={styles.linkContainer} onClick={handleClick.bind(this, "show_bike_service_complete_form")} >Bike Service - Complete</div>
                    <div className={styles.linkContainer} onClick={handleClick.bind(this, "show_bike_service_cancelled_form")}>Bike Service - Cancelled</div>
                </div>
                <div className={styles.carWashContainer}>
                    <h1 className={mainStyles.header}>Car Wash</h1>
                    <div className={styles.linkContainer}>Car Wash - Create</div>
                    <div className={styles.linkContainer}>Car Wash - Update</div>
                    <div className={styles.linkContainer} onClick={handleClick.bind(this, "show_car_wash_start_form")}>Car Wash - Start</div>
                    <div className={styles.linkContainer} onClick={handleClick.bind(this, "show_car_wash_complete_form")}>Car Wash - Complete</div>
                    <div className={styles.linkContainer} onClick={handleClick.bind(this, "show_car_wash_cancel_form")}>Car Wash - Cancel</div>
                </div>
            </div>}

            {showBikeServicePickedUpForm && <BikeServicingPickedUp />}
            {showBikeServiceDroppedAtWorkshopForm && <BikeServicingDroppedAtWorkshop />}
            {showBikeServiceCompleteForm && <BikeServicingComplete />}
            {showBikeServiceCancelledForm && <BikeServicingCancelled />}
            {showCarWashStartForm && <CarWashStart />}
            {showCarWashCompleteForm && <CarWashComplete />}
            {showCarWashCancelForm && <CarWashCancel />}
        </div>
    )
}