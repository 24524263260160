import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import * as React from "react";

function HomePage() {
  return (
    <div>
      <Header />

      <Footer />
    </div>
  );
}
export default HomePage;
