import React from "react";

import styles from "./HomePage.module.css";
import mainStyles from "../Main.module.css";
import bikesImage from "../bikes.png";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import NewBikeList from "../NewBike/NewBikeList";
import UsedBikeList from "../UsedBike/UsedBikeList";
import UsedBike from "../UsedBike/UsedBike";

const UserHome = () => {
  const [showNewBike, setShowNewBike] = React.useState(false);
  const [showUsedBike, setShowUsedBike] = React.useState(false);
  const [showHome, setShowHome] = React.useState(true);
  const [showSellBike, setShowSellBike] = React.useState(false);
  const [modelId, setModelId] = React.useState("");

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    ...theme.applyStyles("dark", {
      backgroundColor: "#1A2027",
    }),
  }));

  const handleNewBikeClick = (e) => {
    setShowNewBike(true);
    setShowHome(false);
    setShowUsedBike(false);
    setShowSellBike(false);
  };

  const handleUsedBikeClick = (e) => {
    setShowUsedBike(true);
    setShowHome(false);
    setShowNewBike(false);
    setShowSellBike(false);
  };

  const handleIconClick = (id) => {
    setModelId(id);
    setShowUsedBike(true);
    setShowHome(false);
    setShowNewBike(false);
    setShowSellBike(false);
  };

  const handleSellBikeClick = (e) => {
    setShowHome(false);
    setShowNewBike(false);
    setShowUsedBike(false);
    setShowSellBike(true);
  };

  return (
    <div>
      {showHome && (
        <div>
          {" "}
          <div className={styles.root}>
            <div className={mainStyles.headingImageDiv}>
              <img
                src={bikesImage}
                alt="Bikes"
                className={mainStyles.bigImage}
              />
            </div>
            <div className={styles.buttonContainer}>
              <div
                onClick={(e) => {
                  handleNewBikeClick(e);
                }}
                className={styles.bikeButton}
              >
                <span>New Bike</span>
              </div>
              <div
                onClick={(e) => {
                  handleUsedBikeClick(e);
                }}
                className={styles.bikeButton}
              >
                <span>Used Bike</span>
              </div>
            </div>
            <div className={styles.iconsGrid}>
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-1201b74a-611e-4045-8b07-c8fa35508c91"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.tvsIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-8683a5ef-4ca5-4c85-9ae4-e8b6be82e3e9"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.bajajIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-e543d954-7bc5-4779-955a-8ca00a6207a4"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.royalEnfieldIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-406516e7-b231-430b-ac12-2dfa56b27b75"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.yamahaIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-5ed66bde-49bf-4ca1-bf33-1421c2634394"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.hondaIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-8683a5ef-4ca5-4c85-9ae4-e8b6be82e3e9"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.suzukiIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-8683a5ef-4ca5-4c85-9ae4-e8b6be82e3e9"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.mahindraIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-8683a5ef-4ca5-4c85-9ae4-e8b6be82e3e9"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.heroIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            "brand-8683a5ef-4ca5-4c85-9ae4-e8b6be82e3e9"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.ktmIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div>
          <div
            onClick={(e) => {
              handleSellBikeClick(e);
            }}
            className={styles.bottomButton}
          >
            <span>Sell Bike</span>
          </div>
        </div>
      )}
      {showNewBike && <NewBikeList />}
      {showUsedBike && <UsedBikeList modelId={modelId} />}
      {showSellBike && <UsedBike />}
    </div>
  );
};

export default UserHome;
