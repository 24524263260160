import { useEffect, useState } from "react"
import styles from "./booking.module.css"
import { API_HOST_URL } from "../Constant/Constant";
import BikeServiceBookingDetail from "./BikeServiceBookingDetail";
import BikeServicing from "../BikeServicing/BikeServicing";


export default function BikeServiceBookingList() {

    const [bikeServiceBookings, setBikeServiceBookings] = useState([]);
    const [showBikeServiceBookingList, setShowBikeServiceBookingList] = useState(false);
    const [showBikeService, setShowBikeService] = useState(false);
    const [showBikeServiceBookingDetail, setShowBikeServiceDetail] = useState(false);
    const [bookingId, setBookingId] = useState();



    useEffect(() => {
        fetch(API_HOST_URL + "/bookings/bikeService")
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setBikeServiceBookings(res);
                setShowBikeServiceBookingList(true);
            });
    }, []);

    const showBikeServicingPage = (bookingId) => {
        setBookingId(bookingId);
        setShowBikeService(true);
        setShowBikeServiceBookingList(false);
        setShowBikeServiceDetail(false);
    }

    const showBikeServiceBookingDetailPage = (bookingId) => {
        setBookingId(bookingId);
        setShowBikeServiceDetail(true);
        setShowBikeServiceBookingList(false);
        setShowBikeService(false);
    }

    return (
        <div>
            {showBikeServiceBookingList && <div><header className={styles.header}>Bike Service Booking List</header>
                <div className={styles.headerRow}>
                    <div className={styles.fieldHeader}>Booking #</div>
                    <div className={styles.fieldHeader}>Customer Name</div>
                    <div className={styles.fieldHeader}>Model</div>
                    <div className={`${styles.fieldHeader} ${styles.serviceDateHeader}`}>Service Date</div>
                    <div className={styles.fieldHeader}>Reg. Number</div>
                    <div className={styles.fieldHeader}>Status</div>
                </div>
                <div className={styles.fieldRow}>
                    {bikeServiceBookings.map((booking) => {
                        return (
                            <div key={booking.id}>
                                <div className={styles.field}>{booking.bookingNumber}</div>
                                <div className={styles.field}>{booking.firstName} {booking.lastName}</div>
                                <div className={styles.field}>{booking.brand} {booking.model}</div>
                                <div className={`${styles.field} ${styles.serviceDate}`}>{booking.scheduledAt}</div>
                                <div className={styles.field}>{booking.registrationNumber}</div>
                                <div className={styles.field}>BOOKED</div>
                                <div className={styles.field}><span onClick={(e) => showBikeServiceBookingDetailPage(booking.bookingId)}>View More</span></div>
                                <div className={styles.field}><span onClick={(e) => showBikeServicingPage(booking.bookingId)}>Create Bike Service</span></div>
                            </div>
                        )
                    })}
                </div>
            </div>}
            {showBikeService && <BikeServicing bookingId={bookingId} />}
            {showBikeServiceBookingDetail && <BikeServiceBookingDetail bookingId={bookingId} />}
        </div>
    )
}