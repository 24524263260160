import { useState, useEffect } from "react";
import styles from "./booking.module.css";
import axios from "axios";
import { Button, TextField } from "@mui/material";
import validator from "validator";
import { API_HOST_URL } from "../Constant/Constant";
import PhoneInTalkRoundedIcon from "@mui/icons-material/PhoneInTalkRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BadgeIcon from "@mui/icons-material/Badge";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";
import ViewListIcon from "@mui/icons-material/ViewList";
import WorkshopList from "../Workshop/WorkshopList";

export default function BikeServiceBooking() {
  const [workshops, setWorkshops] = useState([]);
  const [brands, setBrands] = useState([]);
  const [colors, setColors] = useState([]);
  const [cities, setCities] = useState([]);
  const [models, setModels] = useState([]);
  const [registrationNumber, setRegistrationNumber] = useState();
  const [modelId, setModelId] = useState();
  const [brandId, setBrandId] = useState();
  const [scheduledAt, setScheduledAt] = useState(new Date());
  const [color, setColor] = useState();
  const [customerFullName, setCustomerFullName] = useState();
  const [customerEmail, setCustomerEmail] = useState();
  const [customerMobile, setCustomerMobile] = useState();
  const [customerStreet, setCustomerStreet] = useState();
  const [customerCityId, setCustomerCityId] = useState();
  const [workshopId, setWorkshopId] = useState();
  const [hasError, setHasError] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showWorkshopList, setShowWorkshopList] = useState(false);
  const [showBikeServiceBooking, setShowBikeServiceBooking] = useState(true);

  const fetchInitialData = () => {
    const getAllColors = axios.get(API_HOST_URL + "/colors/");
    const getAllBrands = axios.get(API_HOST_URL + "/brands/");
    const getAllCities = axios.get(API_HOST_URL + "/cities/");
    const getWorkshops = axios.get(API_HOST_URL + "/workshops/idAndName");

    axios.all([getAllColors, getAllBrands, getAllCities, getWorkshops]).then(
      axios.spread((...allData) => {
        setColors(allData[0].data);
        setBrands(allData[1].data);
        setCities(allData[2].data);
        setWorkshops(allData[3].data);
      })
    );
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const loadModels = (e, brandId) => {
    fetch(API_HOST_URL + "/bikeModels/" + brandId)
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setBrandId(brandId);
        setModels(res);
      });
  };

  // Email Validation
  const isValidEmail = (e) => {
    if (customerEmail !== undefined && validator.isEmail(customerEmail)) {
      return true;
    } else {
      return false;
    }
  };

  // Phone Number Validation
  const isValidPhone = () => {
    if (
      customerMobile !== undefined &&
      validator.isMobilePhone(customerMobile)
    ) {
      return true;
    } else {
      return false;
    }
  };

  // eslint-disable-next-line no-extend-native
  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const validateForm = () => {
    if (
      isValidEmail() &&
      isValidPhone() &&
      registrationNumber !== undefined &&
      modelId !== undefined &&
      brandId !== undefined &&
      scheduledAt !== undefined &&
      color !== undefined &&
      customerFullName !== undefined &&
      customerEmail !== undefined &&
      customerStreet !== undefined &&
      customerCityId !== undefined
    )
      return true;

    return false;
  };

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const resetForm = () => {
    setRegistrationNumber("");
    setModelId("");
    setBrandId("");
    setScheduledAt("");
    setColor("");
    setCustomerFullName("");
    setCustomerEmail("");
    setCustomerMobile("");
    setCustomerStreet("");
    setCustomerCityId("");
    setWorkshopId("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (validateForm()) {
      let customerId = localStorage.getItem("userId");
      let customerFirstName = customerFullName.split(" ")[0];
      let customerLastName = customerFullName.split(" ")[1];
      const booking = {
        customerId,
        registrationNumber,
        modelId,
        brandId,
        scheduledAt,
        color,
        customerFirstName,
        customerLastName,
        customerEmail,
        customerMobile,
        customerStreet,
        customerCityId,
        workshopId,
      };

      fetch(API_HOST_URL + "/bookings/bikeService", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(booking),
      })
        .then((res) => {
          onSuccessfulSave();
          resetForm();
        })
        .catch((error) => {
          onFailedSave();
        });
    } else {
      onFailedSave();
    }
  };

  const handleWorkshopClick = (e) => {
    setShowWorkshopList(true);
    setShowBikeServiceBooking(false);
  };

  return isLoading ? (
    <div className={mainStyles.loadingContainer}>
      <img src={loadingImage} alt="loading" />
    </div>
  ) : (
    <div>
      {showBikeServiceBooking && (
        <div className={styles.rootContainer}>
          <form onSubmit={handleSubmit}>
            <header className={mainStyles.header}>Bike Service Booking</header>
            <div className={styles.ddlContainer}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="brandSelect">Brand</InputLabel>
                  <Select
                    labelId="brandSelect"
                    id="brandSelect"
                    // value={age}
                    onChange={(e) => loadModels(e, e.target.value)}
                    className={styles.ddl}
                    defaultValue=""
                  >
                    {brands.map((brand) => (
                      <MenuItem key={brand.id} value={brand.brandId}>
                        {brand.brandName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            <div className={styles.ddlContainer}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="modelSelect">Model</InputLabel>
                  <Select
                    labelId="modelSelect"
                    id="modelSelect"
                    label="Model"
                    onChange={(e) => setModelId(e.target.value)}
                    className={styles.ddl}
                    defaultValue=""
                  >
                    {models.map((model) => (
                      <MenuItem key={model.modelId} value={model.modelId}>
                        {model.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            <div className={styles.ddlContainer}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="colorSelect">Color</InputLabel>
                  <Select
                    labelId="colorSelect"
                    id="colorSelect"
                    label="Color"
                    onChange={(e) => setColor(e.target.value)}
                    className={styles.ddl}
                    defaultValue=""
                  >
                    {colors.map((color) => (
                      <MenuItem key={color} value={color}>
                        {color}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            <div className={styles.dualComponentContainer}>
              <div className={styles.iconDiv}>
                <TwoWheelerIcon className={styles.icon} fontSize="large" />
              </div>
              <div className={styles.textDiv}>
                <TextField
                  className={styles.txtBox}
                  variant="standard"
                  onBlur={(e) => setRegistrationNumber(e.target.value)}
                  label="Registration No."
                />
              </div>
            </div>

            <div className={styles.dualComponentContainer}>
              <div className={styles.iconDiv}>
                <BadgeIcon className={styles.icon} fontSize="large" />
              </div>
              <div className={styles.textDiv}>
                <TextField
                  className={styles.txtBox}
                  variant="standard"
                  onBlur={(e) => setCustomerFullName(e.target.value)}
                  label="Full Name"
                />
              </div>
            </div>

            <div className={styles.dualComponentContainer}>
              <div className={styles.iconDiv}>
                <PhoneInTalkRoundedIcon
                  className={styles.icon}
                  fontSize="large"
                />
              </div>
              <div className={styles.textDiv}>
                <TextField
                  className={styles.txtBox}
                  type="mobile"
                  variant="standard"
                  onBlur={(e) => setCustomerMobile(e.target.value)}
                  label="Contact No"
                />
              </div>
            </div>
            <div className={styles.dualComponentContainer}>
              <div className={styles.iconDiv}>
                <EmailRoundedIcon className={styles.icon} fontSize="large" />
              </div>
              <div className={styles.textDiv}>
                <TextField
                  className={styles.txtBox}
                  type="email"
                  variant="standard"
                  onBlur={(e) => setCustomerEmail(e.target.value)}
                  label="Email"
                />
              </div>
            </div>
            <div className={styles.componentContainer}>
              {/* <div className={styles.iconDiv}>
            <LocationOnIcon className={styles.icon} fontSize="large" />
          </div> */}
              <div className={styles.textDiv}>
                <TextField
                  className={styles.txtBox}
                  multiline
                  rows={3}
                  onBlur={(e) => setCustomerStreet(e.target.value)}
                  label="Address"
                />
              </div>
            </div>

            <div className={styles.ddlContainer}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">City</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={age}
                    label="Age"
                    onChange={(e) => setCustomerCityId(e.target.value)}
                    className={styles.ddl}
                    defaultValue=""
                  >
                    {cities.map((city) => (
                      <MenuItem key={city.cityId} value={city.cityId}>
                        {city.cityName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            <div className={styles.dualComponentContainer}>
              <div
                onClick={(e) => {
                  handleWorkshopClick(e);
                }}
                className={styles.iconDiv}
              >
                <ViewListIcon className={styles.icon} fontSize="large" />
              </div>
              <div className={styles.textDiv}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Workshop
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={age}
                      label="Age"
                      onChange={(e) => setWorkshopId(e.target.value)}
                      className={styles.ddl}
                      defaultValue=""
                    >
                      {workshops.map((workshop) => (
                        <MenuItem key={workshop.id} value={workshop.id}>
                          {workshop.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
            <div className={styles.ddlContainer}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["MobileDateTimePicker"]}>
                  <DemoItem label="Schedule Date/Time">
                    <MobileDateTimePicker
                      className={styles.dateTime}
                      onChange={(e) => setScheduledAt(e)}
                      defaultValue={dayjs(new Date())}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <Button
              className={styles.saveButton}
              type="submit"
              variant="contained"
              endIcon={<ArrowForwardIcon />}
            >
              {" "}
              Book Now
            </Button>
            <div className={styles.componentContainer}>
              {hasError && (
                <p className={mainStyles.error}>
                  Please enter all the mandatory fields with correct value
                </p>
              )}

              {isSaved && (
                <p className={mainStyles.success}>
                  Bike service booking completed.
                </p>
              )}
            </div>
          </form>
        </div>
      )}
      {showWorkshopList && <WorkshopList />}
    </div>
  );
}
