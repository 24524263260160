import { useEffect, useState } from "react"
import styles from "./BikeServicing.module.css"
import { API_HOST_URL } from "../Constant/Constant";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import SaveIcon from '@mui/icons-material/Save';
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";


export default function BikeServicingStatusUpdate() {
    const [bookings, setBookings] = useState([]);
    const [workers, setWorkers] = useState([]);
    const [workshops, setWorkshops] = useState([]);
    const [bikeService, setBikeService] = useState();
    const [isSaved, setIsSaved] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [workerId, setWorkerId] = useState();
    const [workshopId, setWorkshopId] = useState();
    const [remarks, setRemarks] = useState();
    const [droppedAt, setDroppedAt] = useState();
    const [cancelledAt, setCancelledAt] = useState();
    const [totalCost, setTotalCost] = useState();
    const [washingStatusList, setWashingStatusList] = useState([]);
    const [servicingStatus, setServicingStatus] = useState();
    const [dropAddress, setAddress] = useState();
    const [bookingId, setBookingId] = useState();
    const [billingStatus, setBillingStatus] = useState();
    const [paymentStatusList, setPaymentStatusList] = useState();

    const [isLoading, setIsLoading] = useState(false);

    const fetchInitialData = () => {
        const getCarWashBookingIdList = axios.get(API_HOST_URL + "/bookings/bookingNumber/bikeServicing");
        const getWorkers = axios.get(API_HOST_URL + "/workers/");
        const getWorkshops = axios.get(API_HOST_URL + "/workshops/idAndName");
        const getBikeServicingStatusList = axios.get(API_HOST_URL + "/bikeService/statusList");
        const getBillingStatusList = axios.get(API_HOST_URL + "/bills/statusList");

        axios.all([getCarWashBookingIdList, getWorkers, getWorkshops, getBikeServicingStatusList, getBillingStatusList]).then(
            axios.spread((...allData) => {
                setBookings(allData[0].data);
                setWorkers(allData[1].data);
                setWorkshops(allData[2].data);
                setWashingStatusList(allData[3].data);
                setPaymentStatusList(allData[4].data);
            })
        )
    }

    useEffect(() => {
        fetchInitialData();
    }, []);


    const validateForm = () => {

        if (servicingStatus !== undefined && bookingId !== undefined
            && ((servicingStatus === "DROPPED" && droppedAt !== undefined && totalCost > 0.0 && workerId !== undefined)
                || (servicingStatus === "CANCELLED" && cancelledAt !== undefined && totalCost === 0.0)))
            return true;

        return
    }



    const loadCarWash = (id) => {
        setBookingId(id);
        fetch(API_HOST_URL + "/bikeService/" + id)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setBikeService(res);
                setWorkerId(res.workerId);
                setWorkshopId(res.workshopId);
                setServicingStatus(res.status);
                setAddress(res.street);
                setRemarks(res.remarks);
                setAddress(res.address);

            });
    }

    const onSuccessfulSave = () => {
        setIsSaved(true);
        setIsLoading(false);
        setHasError(false);
    }

    const onFailedSave = () => {
        setIsLoading(false);
        setIsSaved(false);
        setHasError(true);
    }

    const resetForm = () => {
        setRemarks('');
        setDroppedAt('');
        setCancelledAt('');
        setBookingId('');
        setTotalCost(0.0);
        setServicingStatus('');
        setWorkerId('');
        setWorkshopId('');
        setAddress('');
        setBillingStatus('');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (validateForm()) {

            const bikeServicing = {
                bookingId, workerId, workshopId, droppedAt, cancelledAt, totalCost, servicingStatus, billingStatus, remarks, dropAddress
            };

            fetch(API_HOST_URL + "/bikeService/", {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(bikeServicing)
            }).then((res) => {
                onSuccessfulSave();
                resetForm();
            }).catch((error) => {
                onFailedSave();
            });
        }
        else {
            onFailedSave();
        }
    }

    return (
        isLoading ? <div className={mainStyles.loading}><img src={loadingImage} alt="loading" /></div> :
            <div>
                <form onSubmit={handleSubmit}>
                    <div className={styles.carWashContainerDiv}>
                        <header className={mainStyles.header}>Bike Servicing Status Update</header>
                        {bookings && <div className={styles.bookingDeailContainer}>

                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Booking #</div><div className={styles.bookingValue}><select className={styles.workerddl} onChange={(e) => loadCarWash(e.target.value)} >
                                <option> Select Booking Id </option>
                                {bookings.map((booking) => (
                                    <option key={booking.bookingId} value={booking.bookingId}>{booking.bookingNumber}</option>
                                ))}

                            </select></div></div>
                        </div>}

                        {bikeService && <div className={styles.container}>




                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Customer Name:</div><div className={styles.bookingValue}>{bikeService.customerName} </div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Model:</div><div className={styles.bookingValue}>{bikeService.model}</div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Color:</div><div className={styles.bookingValue}>{bikeService.color}</div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Registration #:</div><div className={styles.bookingValue}>{bikeService.registrationNumber}</div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Worker:</div><div className={styles.bookingValue}>
                                <select className={styles.workerddl} defaultValue={bikeService.workerId} onChange={(e) => setWorkerId(e.target.value)} >
                                    <option> Select Worker </option>
                                    {workers.map((worker) => (
                                        <option key={worker.id} value={worker.id}>{worker.name}</option>
                                    ))}

                                </select>
                            </div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Workershop:</div><div className={styles.bookingValue}>
                                <select className={styles.workerddl} defaultValue={bikeService.workshopId} onChange={(e) => setWorkshopId(e.target.value)} >
                                    <option> Select Workshop </option>
                                    {workshops.map((workshop) => (
                                        <option key={workshop.id} value={workshop.id}>{workshop.name}</option>
                                    ))}

                                </select>
                            </div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Servicing Status:</div><div className={styles.bookingValue}>
                                <select className={styles.workerddl} defaultValue={bikeService.status} onChange={(e) => setServicingStatus(e.target.value)} >
                                    <option> Select Status </option>
                                    {washingStatusList.map((status) => (
                                        <option key={status} value={status}>{status}</option>
                                    ))}

                                </select>
                            </div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Address:</div><div className={styles.bookingValue}><TextField multiline rows={2} onChange={(e) => setAddress(e.target.value)} label="Enter Address" defaultValue={bikeService.address} /></div></div>
                            {servicingStatus === "DROPPED" && <div className={styles.bookingRow}><div className={styles.bookingKey}>Dropped At:</div>
                                <div className={styles.bookingValue}> <label for="droppedAt"></label>
                                    <input type="datetime-local" onChange={(e) => setDroppedAt(e.target.value)} id="droppedAt" name="droppedAt" /> </div></div>}
                            {servicingStatus === "CANCELLED" && <div className={styles.bookingRow}><div className={styles.bookingKey}>Cancelled At:</div>
                                <div className={styles.bookingValue}> <label for="cancelledAt"></label>
                                    <input type="datetime-local" onChange={(e) => setCancelledAt(e.target.value)} id="cancelledAt" name="cancelledAt" /> </div></div>}

                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Total Cost:</div><div className={styles.bookingValue}>
                                <TextField onChange={(e) => setTotalCost(e.target.value)} label="Enter cost" defaultValue={bikeService.totalCost} />
                            </div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Payment Status:</div><div className={styles.bookingValue}>
                                <select className={styles.workerddl} onChange={(e) => setBillingStatus(e.target.value)} >
                                    <option> Select Status </option>
                                    {paymentStatusList.map((status) => (
                                        <option key={status} value={status}>{status}</option>
                                    ))}

                                </select>
                            </div></div>

                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Remaks:</div><div className={styles.bookingValue}><TextField multiline rows={2} onChange={(e) => setRemarks(e.target.value)} label="Enter Remarks" defaultValue={bikeService.remarks} /></div></div>

                            <div className={styles.component}> <Button type="submit" variant="contained" startIcon={<SaveIcon />}> Update Status</Button></div>
                            <br />
                            {hasError && <p className={mainStyles.error}>Please fill all the mandatory fields.</p>}
                            {isSaved && <p className={mainStyles.success}> Bike Servicing status updated successfully</p>}
                        </div>}
                    </div>
                </form>
            </div>
    )
}