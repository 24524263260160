import { useState, useEffect } from "react";
import axios from "axios";
import { TextField, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import styles from "./UsedBike.module.css";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { API_HOST_URL } from "../Constant/Constant";
import mainStyles from "../Main.module.css";
import loadingImage from "../loading.gif";
import validator from "validator";

export default function UsedBike() {
  const [colors, setColors] = useState([]);
  const [brands, setBrands] = useState([]);
  const [cities, setCities] = useState([]);
  const [models, setModels] = useState([]);
  const [bikeModelId, setBikeModelId] = useState();
  const [purchaseYear, setPurchaseYear] = useState();
  const [brandId, setBrandId] = useState();
  const [color, setColor] = useState();
  const [runningKms, setRunningKms] = useState();
  const [sellerName, setSellerName] = useState();
  const [sellerMobile, setSellerMobile] = useState();
  const [sellerAddress, setSellerAddress] = useState();
  const [sellerCityId, setSellerCityId] = useState();
  const [price, setPrice] = useState();
  const [registrationNumber, setRegistrationNumber] = useState();
  const [description, setDescription] = useState();
  const [modelYear, setModelYear] = useState();
  const [isSaved, setIsSaved] = useState(false);
  const [bikeId, setBikeId] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [topViewImage, setTopViewImage] = useState();
  const [leftViewImage, setLeftViewImage] = useState();
  const [rightViewImage, setRightViewImage] = useState();
  const [frontViewImage, setFrontViewImage] = useState();
  const [backViewImage, setBackViewImage] = useState();
  const [topViewImageUploaded, setTopViewImageUploaded] = useState(false);
  const [leftViewImageUploaded, setLeftViewImageUploaded] = useState(false);
  const [rightViewImageUploaded, setRightViewImageUploaded] = useState(false);
  const [frontViewImageUploaded, setFrontViewImageUploaded] = useState(false);
  const [backViewImageUploaded, setBackViewImageUploaded] = useState(false);

  const [topViewImageUploadFail, setTopViewImageUploadFail] = useState(false);
  const [leftViewImageUploadFail, setLeftViewImageUploadFail] = useState(false);
  const [rightViewImageUploadFail, setRightViewImageUploadFail] =
    useState(false);
  const [frontViewImageUploadFail, setFrontViewImageUploadFail] =
    useState(false);
  const [backViewImageUploadFail, setBackViewImageUploadFail] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);

  const fetchInitialData = () => {
    const getAllColors = axios.get(API_HOST_URL + "/colors/");
    const getAllBrands = axios.get(API_HOST_URL + "/brands/");
    const getAllCities = axios.get(API_HOST_URL + "/cities/");

    axios.all([getAllColors, getAllBrands, getAllCities]).then(
      axios.spread((...allData) => {
        setColors(allData[0].data);
        setBrands(allData[1].data);
        setCities(allData[2].data);
      })
    );
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const loadModels = (e, brand) => {
    fetch(API_HOST_URL + "/bikeModels/" + brand)
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setBrandId(brand);
        setModels(res);
      });
  };

  const handleSubmitTop = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", topViewImage);
    formData.append("imageAngle", "TOP");
    let saved = saveImage(formData);
    if (saved) setTopViewImageUploaded(true);
    else setTopViewImageUploadFail(true);
  };

  const handleSubmitFront = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", frontViewImage);
    formData.append("imageAngle", "FRONT");
    let saved = saveImage(formData);
    if (saved) setFrontViewImageUploaded(true);
    else setFrontViewImageUploadFail(true);
  };

  const handleSubmitLeft = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", leftViewImage);
    formData.append("imageAngle", "LEFT");
    let saved = saveImage(formData);
    if (saved) setLeftViewImageUploaded(true);
    else setLeftViewImageUploadFail(true);
  };

  const handleSubmitRight = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", rightViewImage);
    formData.append("imageAngle", "RIGHT");
    let saved = saveImage(formData);
    if (saved) setRightViewImageUploaded(true);
    else setRightViewImageUploadFail(true);
  };

  const handleSubmitBack = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", backViewImage);
    formData.append("imageAngle", "BACK");
    let saved = saveImage(formData);
    if (saved) setBackViewImageUploaded(true);
    else setBackViewImageUploadFail(true);
  };

  const saveImage = (params) => {
    params.append("bikeId", bikeId);
    var saved = true;

    fetch(API_HOST_URL + "/usedBikes/images/", {
      method: "POST",
      body: params,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setBikeId(res.bikeId);
        saved = true;
      })
      .catch((error) => {
        console.log(error);
        saved = false;
      });

    return saved;
  };

  const years = [
    2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011,
    2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023,
    2024,
  ];

  const validatePhoneNumber = (number) => {
    const isValidPhoneNumber = validator.isMobilePhone(number);
    setSellerMobile(number);
    setIsValidPhoneNumber(isValidPhoneNumber);
  };

  const isValidForm = () => {
    if (
      bikeId !== undefined &&
      bikeId !== "" &&
      brandId !== undefined &&
      bikeModelId !== undefined &&
      color !== undefined &&
      purchaseYear !== undefined &&
      runningKms !== undefined &&
      sellerName !== undefined &&
      sellerMobile !== undefined &&
      sellerAddress !== undefined &&
      sellerCityId !== undefined &&
      price !== undefined &&
      registrationNumber !== undefined &&
      modelYear &&
      frontViewImageUploaded &&
      isValidPhoneNumber
    )
      return true;
    return false;
  };

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const resetForm = () => {
    setBikeModelId();
    setBrandId();
    setColor();
    setPurchaseYear();
    setRunningKms();
    setSellerName();
    setSellerMobile();
    setSellerAddress();
    setSellerCityId();
    setPrice();
    setRegistrationNumber();
    setDescription();
    setModelYear();
    setTopViewImage();
    setLeftViewImage();
    setRightViewImage();
    setFrontViewImage();
    setBackViewImage();
    setTopViewImageUploaded(false);
    setLeftViewImageUploaded(false);
    setRightViewImageUploaded(false);
    setFrontViewImageUploaded(false);
    setBackViewImageUploaded(false);
    setTopViewImageUploadFail(false);
    setLeftViewImageUploadFail(false);
    setRightViewImageUploadFail(false);
    setFrontViewImageUploadFail(false);
    setBackViewImageUploadFail(false);
    setIsValidPhoneNumber(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    var customerId = localStorage.getItem("userId");
    const usedBike = {
      customerId,
      brandId,
      bikeModelId,
      color,
      description,
      purchaseYear,
      runningKms,
      sellerName,
      sellerMobile,
      sellerAddress,
      sellerCityId,
      price,
      registrationNumber,
      modelYear,
      bikeId,
    };

    if (isValidForm()) {
      fetch(API_HOST_URL + "/usedBikes/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(usedBike),
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          onSuccessfulSave();
          resetForm();
        })
        .catch((error) => {
          onFailedSave();
        });
    } else {
      onFailedSave();
    }
  };

  return isLoading ? (
    <div className={mainStyles.loading}>
      <img src={loadingImage} alt="loading" />
    </div>
  ) : (
    <div>
      <header className={mainStyles.header}>Used Bike Registration</header>

      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onChange={(e) => setSellerName(e.target.value)}
          label="Enter Seller Name"
        />
      </div>

      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          type="phone"
          variant="standard"
          onChange={(e) => validatePhoneNumber(e.target.value)}
          label="Enter Seller Mobile"
        />
      </div>

      <div className={styles.componentContainer}>
        <TextField
          className={`${styles.component}`}
          onChange={(e) => setSellerAddress(e.target.value)}
          label="Enter Address"
          multiline
          rows={3}
        />
      </div>

      <div className={styles.ddlContainer}>
        <select
          className={`${styles.ddl}`}
          onChange={(e) => setSellerCityId(e.target.value)}
        >
          <option> Select City </option>
          {cities.map((city) => (
            <option key={city.cityId} value={city.cityId}>
              {city.cityName}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.ddlContainer}>
        <select
          className={`${styles.ddl}`}
          onChange={(e) => loadModels(e, e.target.value)}
        >
          <option> Select Brand </option>
          {brands.map((brand) => (
            <option key={brand.brandId} value={brand.brandId}>
              {brand.brandName}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.ddlContainer}>
        <select
          className={`${styles.ddl}`}
          onChange={(e) => setBikeModelId(e.target.value)}
        >
          <option> Select Model </option>
          {models.map((model) => (
            <option key={model.modelId} value={model.modelId}>
              {model.name}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.ddlContainer}>
        <select
          className={`${styles.ddl}`}
          onChange={(e) => setColor(e.target.value)}
        >
          <option> Select Color </option>
          {colors.map((color) => (
            <option key={color} value={color}>
              {color}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onChange={(e) => setRegistrationNumber(e.target.value)}
          label="Enter Registration Number"
        />
      </div>

      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          type="number"
          variant="standard"
          onChange={(e) => setRunningKms(e.target.value)}
          label="Enter Running KMs"
        />
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          type="number"
          variant="standard"
          onChange={(e) => setPrice(e.target.value)}
          label="Enter Selling Price"
        />
      </div>

      <div className={styles.ddlContainer}>
        <select
          className={`${styles.ddl}`}
          onChange={(e) => setPurchaseYear(e.target.value)}
        >
          <option> Select Purchase Year </option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.ddlContainer}>
        <select
          className={`${styles.ddl}`}
          onChange={(e) => setModelYear(e.target.value)}
        >
          <option> Select Model Year </option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={`${styles.component}`}
          onChange={(e) => setDescription(e.target.value)}
          label="Enter remarks"
          multiline
          rows={3}
        />
      </div>

      <Divider>
        <Chip label="Image for Bike Top view" size="small" />
      </Divider>
      <div className={styles.uploadFormContainer}>
        <form onSubmit={handleSubmitTop} encType="multipart/form-data">
          <input
            type="file"
            onChange={(e) => setTopViewImage(e.target.files[0])}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {topViewImageUploaded && (
            <p className="inline"> Image uploaded Successfully</p>
          )}
          {topViewImageUploadFail && (
            <p className="inline"> Image upload Failed</p>
          )}
        </form>
      </div>
      <Divider>
        <Chip label="Image for Bike Front view" size="small" />
      </Divider>

      <div className={styles.uploadFormContainer}>
        <form onSubmit={handleSubmitFront} encType="multipart/form-data">
          <input
            type="file"
            onChange={(e) => setFrontViewImage(e.target.files[0])}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {frontViewImageUploaded && (
            <p className="inline"> Image uploaded Successfully</p>
          )}
          {frontViewImageUploadFail && (
            <p className="inline"> Image upload Failed</p>
          )}
        </form>
      </div>

      <Divider>
        <Chip label="Image for Bike Left view" size="small" />
      </Divider>
      <div className={styles.uploadFormContainer}>
        <form onSubmit={handleSubmitLeft} encType="multipart/form-data">
          <input
            type="file"
            onChange={(e) => setLeftViewImage(e.target.files[0])}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {leftViewImageUploaded && (
            <p className="inline"> Image uploaded Successfully</p>
          )}
          {leftViewImageUploadFail && (
            <p className="inline"> Image upload Failed</p>
          )}
        </form>
      </div>

      <Divider>
        <Chip label="Image for Bike Right view" size="small" />
      </Divider>
      <div className={styles.uploadFormContainer}>
        <form onSubmit={handleSubmitRight} encType="multipart/form-data">
          <input
            type="file"
            onChange={(e) => setRightViewImage(e.target.files[0])}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {rightViewImageUploaded && (
            <p className="inline"> Image uploaded Successfully</p>
          )}
          {rightViewImageUploadFail && (
            <p className="inline"> Image upload Failed</p>
          )}
        </form>
      </div>

      <Divider>
        <Chip label="Image for Bike Back view" size="small" />
      </Divider>

      <div className={styles.uploadFormContainer}>
        <form onSubmit={handleSubmitBack} encType="multipart/form-data">
          <input
            type="file"
            onChange={(e) => setBackViewImage(e.target.files[0])}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {backViewImageUploaded && (
            <p className="inline"> Image uploaded Successfully</p>
          )}
          {backViewImageUploadFail && (
            <p className="inline"> Image upload Failed</p>
          )}
        </form>
      </div>

      <Divider></Divider>

      <div className={styles.componentContainer}>
        <Button
          onClick={handleSubmit}
          className={`${styles.component}`}
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
        >
          {" "}
          Save Bike
        </Button>
      </div>

      <div className={styles.componentContainer}>
        {isSaved && (
          <div>
            <p className={mainStyles.success}>Bike Saved Successfully</p>
          </div>
        )}
        {hasError && (
          <p className={mainStyles.error}>
            {" "}
            Note: All fields are mandatory except remarks.
          </p>
        )}
        {!isValidPhoneNumber && (
          <p className={mainStyles.error}>Phone number is invalid</p>
        )}
      </div>
    </div>
  );
}
