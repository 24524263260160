import { useEffect, useState } from "react"
import styles from "./BikeServicing.module.css"
import mainStyles from "../Main.module.css"
import { API_HOST_URL } from "../Constant/Constant";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import SaveIcon from '@mui/icons-material/Save';
import loadingImage from "../loading.gif";


export default function BikeServicing({ bookingId }) {
    const [booking, setBooking] = useState();
    const [address, setAddress] = useState();
    const [workers, setWorkers] = useState([]);
    const [workerId, setWorkerId] = useState();
    const [workshopId, setWorkshopId] = useState();
    const [remarks, setRemarks] = useState();
    const [hasError, setHasError] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [workshops, setWorkshops] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const fetchInitialData = () => {
        const getBikeServiceBooking = axios.get(API_HOST_URL + "/bookings/bikeService/" + bookingId);
        const getAllWorkers = axios.get(API_HOST_URL + "/workers/");
        const getAllWorkshops = axios.get(API_HOST_URL + "/workshops/idAndName");

        axios.all([getBikeServiceBooking, getAllWorkers, getAllWorkshops]).then(
            axios.spread((...allData) => {
                setBooking(allData[0].data);
                setWorkers(allData[1].data);
                setWorkshops(allData[2].data);
            })
        )
    }

    useEffect(() => {
        fetchInitialData();
    },[]);

    // eslint-disable-next-line no-extend-native
    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }


    const validateForm = () => {

        if (address !== undefined && workerId !== '' && workshopId !== '')
            return true;

        return
    }

    const onSuccessfulSave = () => {
        setIsSaved(true);
        setIsLoading(false);
        setHasError(false);
    }

    const onFailedSave = () => {
        setIsLoading(false);
        setIsSaved(false);
        setHasError(true);
    }

    const resetForm = () => {
        setAddress('');
        setWorkerId('');
        setWorkshopId('');
        setRemarks('');

    }



    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);


        if (validateForm()) {
            const bikeServicing = {
                bookingId, workerId, workshopId, remarks, address
            };

            fetch(API_HOST_URL + "/bikeService/", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(bikeServicing)
            }).then((res) => {
                onSuccessfulSave();
                resetForm();
            }).catch((error) => {
                onFailedSave();
            });
        }
        else {
            onFailedSave();
        }
    }

    return (
        <div>
            {isLoading && <div className={mainStyles.loadingContainer}><img src={loadingImage} alt="loading" /></div>}
            <div>
                <form onSubmit={handleSubmit}>
                    <div>
                        <header className={mainStyles.header}>Bike Servicing</header>
                        {booking && <div className={styles.bookingDeailContainer}>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Booking #:</div><div className={styles.bookingValue}>{booking.bookingNumber} </div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Customer Name:</div><div className={styles.bookingValue}>{booking.firstName} {booking.lastName}</div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Model:</div><div className={styles.bookingValue}>{booking.brand} {booking.carModel}</div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Bike Servicing Date:</div><div className={styles.bookingValue}>{booking.scheduledAt}</div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Estimated Price:</div><div className={styles.bookingValue}>{booking.estimatedPrice}</div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Address:</div><div className={styles.bookingValue}><TextField multiline rows={2} onChange={(e) => setAddress(e.target.value)} label="Enter Street" defaultValue={booking.street} /></div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Worker:</div><div className={styles.bookingValue}><select className={styles.workerddl} defaultValue={booking.workerId} onChange={(e) => setWorkerId(e.target.value)} >
                                <option> Select Worker </option>
                                {workers.map((worker) => (
                                    <option key={worker.id} value={worker.id}>{worker.name}</option>
                                ))}

                            </select></div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Workshop:</div><div className={styles.bookingValue}><select className={styles.workerddl} defaultValue={booking.workshopId} onChange={(e) => setWorkshopId(e.target.value)} >
                                <option> Select Workshop </option>
                                {workshops.map((workshop) => (
                                    <option key={workshop.id} value={workshop.id}>{workshop.name}</option>
                                ))}

                            </select></div></div>


                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Remarks:</div><div className={styles.bookingValue}><TextField multiline rows={2} onChange={(e) => setRemarks(e.target.value)} label="Enter Remarks" /></div></div>
                            <Button className={styles.saveButton} type="submit" variant="contained" startIcon={<SaveIcon />}> Create Bike Servicing</Button>
                            {hasError && <p className={mainStyles.error}>Please fill all the mandatory fields.</p>}
                            {isSaved && <p className={mainStyles.success}> Bike servicing created Successfully</p>}
                        </div>}
                    </div>
                </form>
            </div>
        </div>
    )

}
