import { useEffect, useState } from "react";
import styles from "./NewBike.module.css";
import { Button, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import axios from "axios";
import { API_HOST_URL } from "../Constant/Constant";
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";

export default function NewBike() {
  const [isSaved, setIsSaved] = useState(false);

  const [models, setModels] = useState([]);
  const [brands, setBrands] = useState([]);
  const [colors, setColors] = useState([]);
  const [brandId, setBrandId] = useState();
  const [modelId, setModelId] = useState();
  const [color, setColor] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  //States for form fields
  const [summary, setSummary] = useState();
  const [mileage, setMileage] = useState();
  const [engineCapacity, setEngineCapacity] = useState();
  const [exShowroomPrice, setExShowroomPrice] = useState();
  const [variant, setVariant] = useState();
  const [height, setHeight] = useState();
  const [length, setLength] = useState();
  const [service1, setService1] = useState();
  const [service2, setService2] = useState();
  const [service3, setService3] = useState();
  const [service4, setService4] = useState();
  const [abs, setAbs] = useState();
  const [power, setPower] = useState();
  const [torque, setTorque] = useState();
  const [fuelCapacity, setFuelCapacity] = useState();
  const [launchDate, setLaunchDate] = useState();

  const [topViewImage, setTopViewImage] = useState();
  const [leftViewImage, setLeftViewImage] = useState();
  const [rightViewImage, setRightViewImage] = useState();
  const [frontViewImage, setFrontViewImage] = useState();
  const [backViewImage, setBackViewImage] = useState();
  const [topViewImageUploaded, setTopViewImageUploaded] = useState(false);
  const [leftViewImageUploaded, setLeftViewImageUploaded] = useState(false);
  const [rightViewImageUploaded, setRightViewImageUploaded] = useState(false);
  const [frontViewImageUploaded, setFrontViewImageUploaded] = useState(false);
  const [backViewImageUploaded, setBackViewImageUploaded] = useState(false);

  const [topViewImageUploadFail, setTopViewImageUploadFail] = useState(false);
  const [leftViewImageUploadFail, setLeftViewImageUploadFail] = useState(false);
  const [rightViewImageUploadFail, setRightViewImageUploadFail] =
    useState(false);
  const [frontViewImageUploadFail, setFrontViewImageUploadFail] =
    useState(false);
  const [backViewImageUploadFail, setBackViewImageUploadFail] = useState(false);
  const [bikeId, setBikeId] = useState("");

  const fetchInitialData = () => {
    const getAllColors = axios.get(API_HOST_URL + "/colors/");
    const getAllBrands = axios.get(API_HOST_URL + "/brands/");

    axios.all([getAllColors, getAllBrands]).then(
      axios.spread((...allData) => {
        setColors(allData[0].data);
        setBrands(allData[1].data);
      })
    );
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const loadModels = (brand) => {
    fetch(API_HOST_URL + "/bikeModels/" + brand)
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setBrandId(brand);
        setModels(res);
      });
  };

  const handleSubmitTop = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", topViewImage);
    formData.append("imageAngle", "TOP");
    let saved = saveImage(formData);
    if (saved) setTopViewImageUploaded(true);
    else setTopViewImageUploadFail(true);
  };

  const handleSubmitFront = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", frontViewImage);
    formData.append("imageAngle", "FRONT");
    let saved = saveImage(formData);
    if (saved) setFrontViewImageUploaded(true);
    else setFrontViewImageUploadFail(true);
  };

  const handleSubmitLeft = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", leftViewImage);
    formData.append("imageAngle", "LEFT");
    let saved = saveImage(formData);
    if (saved) setLeftViewImageUploaded(true);
    else setLeftViewImageUploadFail(true);
  };

  const handleSubmitRight = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", rightViewImage);
    formData.append("imageAngle", "RIGHT");
    let saved = saveImage(formData);
    if (saved) setRightViewImageUploaded(true);
    else setRightViewImageUploadFail(true);
  };

  const handleSubmitBack = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", backViewImage);
    formData.append("imageAngle", "BACK");
    let saved = saveImage(formData);
    if (saved) setBackViewImageUploaded(true);
    else setBackViewImageUploadFail(true);
  };

  const saveImage = (params) => {
    params.append("bikeId", bikeId);
    var saved = true;
    var ext = "";

    if (params.get("file") !== undefined)
      ext = params.get("file").name.split(".").pop().toLowerCase();
    if (
      !(
        ext === "jpeg" ||
        ext === "png" ||
        ext === "jpg" ||
        ext === "webp" ||
        ext === "avif"
      )
    ) {
      alert("Please upload a jpeg/png image file");
      return false;
    } else if (params.get("file") === undefined) {
      alert("Please select the image file to upload");
      return false;
    }

    fetch(API_HOST_URL + "/newBikes/images/", {
      method: "POST",
      body: params,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setBikeId(res.bikeId);
        saved = true;
      })
      .catch((error) => {
        console.log(error);
        saved = false;
      });

    return saved;
  };

  const isValidForm = () => {
    if (
      bikeId !== undefined &&
      bikeId !== "" &&
      brandId !== undefined &&
      modelId !== undefined &&
      color !== undefined &&
      summary !== undefined &&
      mileage !== undefined &&
      engineCapacity !== undefined &&
      exShowroomPrice !== undefined &&
      variant !== undefined &&
      height !== undefined &&
      length !== undefined &&
      service1 !== undefined &&
      service2 !== undefined &&
      service3 !== undefined &&
      service4 !== undefined &&
      abs !== undefined &&
      power !== undefined &&
      torque !== undefined &&
      fuelCapacity !== undefined &&
      frontViewImageUploaded
    )
      return true;
    return false;
  };

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const resetForm = () => {
    setBrandId();
    setModelId();
    setColor();
    setSummary();
    setMileage();
    setEngineCapacity();
    setExShowroomPrice();
    setVariant();
    setHeight();
    setLength();
    setService1();
    setService2();
    setService3();
    setService4();
    setAbs();
    setPower();
    setTorque();
    setFuelCapacity();
    setLaunchDate();
    setTopViewImageUploaded(false);
    setLeftViewImageUploaded(false);
    setRightViewImageUploaded(false);
    setFrontViewImageUploaded(false);
    setBackViewImageUploaded(false);
    setTopViewImageUploadFail(false);
    setLeftViewImageUploadFail(false);
    setRightViewImageUploadFail(false);
    setFrontViewImageUploadFail(false);
    setBackViewImageUploadFail(false);
    setBikeId("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (isValidForm()) {
      const newBike = {
        brandId,
        modelId,
        color,
        summary,
        mileage,
        engineCapacity,
        exShowroomPrice,
        variant,
        height,
        length,
        service1,
        service2,
        service3,
        service4,
        abs,
        power,
        torque,
        fuelCapacity,
        launchDate,
        bikeId,
      };

      fetch(API_HOST_URL + "/newBikes/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newBike),
      })
        .then((res) => {
          onSuccessfulSave();
          resetForm();
        })
        .catch((error) => {
          onFailedSave();
        });
    } else {
      onFailedSave();
    }
  };

  return isLoading ? (
    <div className={mainStyles.loadingContainer}>
      <img src={loadingImage} alt="loading" />
    </div>
  ) : (
    <div>
      <header className={mainStyles.header}>Add New Bike</header>

      <Divider>
        <Chip label="Model Information" size="small" />
      </Divider>
      <div className={styles.ddlContainer}>
        <select
          className={styles.ddl}
          onChange={(e) => loadModels(e.target.value)}
        >
          <option> Select Brand </option>
          {brands.map((brand) => (
            <option key={brand.brandId} value={brand.brandId}>
              {brand.brandName}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.ddlContainer}>
        <select
          className={styles.ddl}
          onChange={(e) => setModelId(e.target.value)}
        >
          <option> Select Model </option>
          {models.map((model) => (
            <option key={model.modelId} value={model.modelId}>
              {model.name}
            </option>
          ))}
        </select>{" "}
      </div>
      <div className={styles.ddlContainer}>
        <select
          className={styles.ddl}
          onChange={(e) => setColor(e.target.value)}
        >
          <option> Select Color </option>
          {colors.map((color) => (
            <option key={color} value={color}>
              {color}
            </option>
          ))}
        </select>{" "}
      </div>

      <div className={styles.componentContainer}>
        <TextField
          className={`${styles.component} ${styles.margin_top}`}
          onChange={(e) => setSummary(e.target.value)}
          label="Enter Summary"
          multiline
          rows={5}
        />
      </div>
      <Divider>
        <Chip label="Bike Specification" size="small" />
      </Divider>

      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onChange={(e) => setMileage(e.target.value)}
          label="Enter Milege"
        />
        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            variant="standard"
            onChange={(e) => setEngineCapacity(e.target.value)}
            label="Enter Engine Capacity"
          />{" "}
        </div>
        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            variant="standard"
            onBlur={(e) => setExShowroomPrice(e.target.value)}
            label="Ex Showroom Price"
          />
        </div>
        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            variant="standard"
            onBlur={(e) => setVariant(e.target.value)}
            label="Enter variant"
          />
        </div>
        <div className={styles.componentContainer}></div>
        <TextField
          className={styles.component}
          variant="standard"
          onBlur={(e) => setHeight(e.target.value)}
          label="Enter Height"
        />
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onBlur={(e) => setLength(e.target.value)}
          label="Enter Length"
        />
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onBlur={(e) => setService1(e.target.value)}
          label="Service 1"
        />
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onBlur={(e) => setService2(e.target.value)}
          label="Service 2"
        />
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onBlur={(e) => setService3(e.target.value)}
          label="Service 3"
        />
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onBlur={(e) => setService4(e.target.value)}
          label="Service 4"
        />
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onBlur={(e) => setAbs(e.target.value)}
          label="Enter Abs"
        />
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onBlur={(e) => setPower(e.target.value)}
          label="Enter Power"
        />
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onBlur={(e) => setTorque(e.target.value)}
          label="Enter Torque"
        />
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onBlur={(e) => setFuelCapacity(e.target.value)}
          label="Enter fuel capacity"
        />
      </div>
      <div className={styles.componentContainer}>
        <label className={styles.component} for="launchDate">
          Launch Date{" "}
        </label>
        <input
          type="date"
          onBlur={(e) => setLaunchDate(e.target.value)}
          id="launchDate"
          name="launchDate"
        />{" "}
      </div>
      <div className={styles.uploadFormContainer}>
        <form onSubmit={handleSubmitTop} encType="multipart/form-data">
          <input
            type="file"
            onChange={(e) => setTopViewImage(e.target.files[0])}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {topViewImageUploaded && (
            <p className="inline"> Image uploaded Successfully</p>
          )}
          {topViewImageUploadFail && (
            <p className="inline"> Image upload Failed</p>
          )}
        </form>{" "}
      </div>

      <Divider>
        <Chip label="Image for Bike Front view" size="small" />
      </Divider>

      <div className={styles.uploadFormContainer}>
        <form onSubmit={handleSubmitFront} encType="multipart/form-data">
          <input
            type="file"
            onChange={(e) => setFrontViewImage(e.target.files[0])}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {frontViewImageUploaded && (
            <p className="inline"> Image uploaded Successfully</p>
          )}
          {frontViewImageUploadFail && (
            <p className="inline"> Image upload Failed</p>
          )}
        </form>{" "}
      </div>

      <Divider>
        <Chip label="Image for Bike Left view" size="small" />
      </Divider>
      <div className={styles.uploadFormContainer}>
        <form onSubmit={handleSubmitLeft} encType="multipart/form-data">
          <input
            type="file"
            onChange={(e) => setLeftViewImage(e.target.files[0])}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {leftViewImageUploaded && (
            <p className="inline"> Image uploaded Successfully</p>
          )}
          {leftViewImageUploadFail && (
            <p className="inline"> Image upload Failed</p>
          )}
        </form>
      </div>
      <Divider>
        <Chip label="Image for Bike Right view" size="small" />
      </Divider>

      <div className={styles.uploadFormContainer}>
        <form onSubmit={handleSubmitRight} encType="multipart/form-data">
          <input
            type="file"
            onChange={(e) => setRightViewImage(e.target.files[0])}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {rightViewImageUploaded && (
            <p className="inline"> Image uploaded Successfully</p>
          )}
          {rightViewImageUploadFail && (
            <p className="inline"> Image upload Failed</p>
          )}
        </form>{" "}
      </div>

      <Divider>
        <Chip label="Image for Bike Back view" size="small" />
      </Divider>

      <div className={styles.uploadFormContainer}>
        <form onSubmit={handleSubmitBack} encType="multipart/form-data">
          <input
            type="file"
            onChange={(e) => setBackViewImage(e.target.files[0])}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {backViewImageUploaded && (
            <p className="inline"> Image uploaded Successfully</p>
          )}
          {backViewImageUploadFail && (
            <p className="inline"> Image upload Failed</p>
          )}
        </form>{" "}
      </div>

      <div className={styles.componentContainer}>
        <Button
          onClick={handleSubmit}
          className={styles.saveBtn}
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
        >
          {" "}
          Save New Bike
        </Button>{" "}
      </div>
      <div className={styles.componentContainer}>
        {isSaved && (
          <div>
            <p className={mainStyles.success}>Bike Saved Successfully</p>
          </div>
        )}
        {hasError && (
          <div>
            <p className={mainStyles.error}>
              Some error occurred, please try later
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
