import { useState } from "react";
import styles from "./Country.module.css";
import { Button, TextField } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { API_HOST_URL } from "../Constant/Constant";
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";
import axios from "axios";

export default function Country() {


    const [countryName, setCountryName] = useState('');
    const [countryDescription, setCountryDescription] = useState('');
    const [isSaved, setIsSaved] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);


    const onSuccessfulSave = () => {
        setIsSaved(true);
        setIsLoading(false);
        setHasError(false);
    }

    const onFailedSave = () => {
        setIsLoading(false);
        setIsSaved(false);
        setHasError(true);
    }

    const resetForm = () => {
        setCountryName('');
        setCountryDescription('');
    }



    const handleSubmit = (e) => {
        if (countryName !== '') {
            e.preventDefault();
            const country = { countryName, countryDescription };
            setIsLoading(true);

            fetch(API_HOST_URL + "/countries/", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(country)
            }).then(() => {
                onSuccessfulSave();
                resetForm();

            }).catch((error) => {
                onFailedSave();
            });
        }
        else {
            onFailedSave();
        }


    }


    return (
        isLoading ? <div className={mainStyles.loadingContainer}><img src={loadingImage} alt="loading" /></div> :
            <div className={styles.mainDiv}>
                <header className={mainStyles.header}>Add Country</header>
                <form onSubmit={handleSubmit}>
                    <div className={styles.componentContainer}>
                        <TextField className={styles.component} variant="standard" onChange={(e) => setCountryName(e.target.value)} label="Enter Country Name" />
                    </div>
                    <div className={styles.componentContainer}>
                        <TextField className={styles.component} onChange={(e) => setCountryDescription(e.target.value)} label="Enter Country Description" multiline rows={3} />
                    </div>
                    <div className={styles.componentContainer}>
                        <Button className={styles.saveBtn} type="submit" variant="contained" startIcon={<SaveIcon />}> Save Country</Button>
                    </div>
                    <div className={styles.componentContainer}>
                        {isSaved && <div><p className={mainStyles.success}>Country Saved Successfully</p></div>}
                        {hasError && <div><p className={mainStyles.error}>Country Saved Successfully</p></div>}
                    </div>
                </form>
            </div>
    )
}