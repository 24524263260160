import { useEffect, useState } from "react";
import { Footer } from "../Footer/Footer";
import { HeaderPlain } from "../Header/HeaderPlain";
import { API_HOST_URL } from "../Constant/Constant";
import styles from "./Review.module.css";
import AccountCircle from '@mui/icons-material/AccountCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StarSharp from '@mui/icons-material/StarSharp';
import { TextField, Button } from "@mui/material";
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";


export default function ReviewBikeServicing() {
    let bikeServicingId = (new URLSearchParams(window.location.search)).get("bikeServicingId");
    let customerId = (new URLSearchParams(window.location.search)).get("customerId");
    const [bikeService, setBikeService] = useState();

    const [serviceRatingTemp, setServiceRating] = useState(3);
    const [workshopRatingTemp, setWorkshopRating] = useState(3);

    const [serviceReviewText, setServiceReviewText] = useState('');
    const [workshopReviewText, setWorkshopReviewText] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {


        fetch(API_HOST_URL + "/bikeService/byBikeServicingId/" + bikeServicingId)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setBikeService(res);
            });
    });

    const validateForm = () => {
        let isValid = true;
        if (serviceReviewText === '' || serviceReviewText === undefined || workshopReviewText === '' || workshopReviewText === undefined) {
            isValid = false;
        }
        return isValid;
    }

    const onSuccessfulSave = () => {
        setIsSaved(true);
        setIsLoading(false);
        setHasError(false);
    }

    const onFailedSave = () => {
        setIsLoading(false);
        setIsSaved(false);
        setHasError(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let serviceRating = serviceRatingTemp + 1;
        let workshopRating = workshopRatingTemp + 1;
        const review = { customerId, bikeServicingId, serviceRating, serviceReviewText, workshopRating, workshopReviewText };
        setIsLoading(true);

        if (validateForm()) {

            fetch(API_HOST_URL + "/reviews/picknDrop", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(review)
            }).then((res) => {
                onSuccessfulSave();
            }).catch((error) => {
                onFailedSave();
            });
        }
        else {
            onFailedSave();
        }


    }

    return (

        isLoading ? <div className={styles.loadingContainer}><img src={loadingImage} alt="loading" /></div> :

            <div className={styles.serviceRootContainer}>
                <HeaderPlain />

                <div className={styles.pickAndDropContainer}>
                    <div className={mainStyles.header}>Rate Pick & Drop Service</div>
                    <div >
                        {bikeService && <div className={styles.customerDetailContainer}>
                            <div className={styles.customerDetailInnerDiv}>
                                <div className={styles.profilePicDiv}>
                                    <AccountCircle sx={{ fontSize: 100 }} />
                                </div>
                                <div className={styles.customerDetailDiv}>
                                    <div className={styles.name}>{bikeService.customerName}</div>
                                    <div className={styles.locationIcon}><LocationOnIcon fontSize="large" /> {bikeService.address}, {bikeService.city}</div>
                                </div>


                            </div>
                        </div>}
                        <div>
                            x<div className={styles.starContainer}>{[...Array(5)].map((item, i) => <div className={(i <= serviceRatingTemp ? styles.starActive : styles.starInactive)} key={i} onClick={(e) => setServiceRating(i)}><StarSharp sx={{ fontSize: 60 }} /></div>)}</div>
                            <div className={styles.reviewContainer}>
                                <TextField multiline rows={5} className={styles.reviewText} onBlur={(e) => setServiceReviewText(e.target.value)} placeholder="Write your review here"></TextField>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.workshopContainer}>
                    <div className={styles.heading}>Rate Workshop</div>


                    <div>
                        <div className={styles.starContainer}>{[...Array(5)].map((item, i) => <div className={(i <= workshopRatingTemp ? styles.starActive : styles.starInactive)} key={i} onClick={(e) => setWorkshopRating(i)}><StarSharp sx={{ fontSize: 60 }} /></div>)}</div>
                        <div className={styles.reviewContainer}>
                            <TextField multiline rows={5} className={styles.reviewText} onBlur={(e) => setWorkshopReviewText(e.target.value)} placeholder="Write your review here"></TextField>
                        </div>
                        <div className={styles.btnContainer}>
                            <Button className={styles.saveBtn} onClick={handleSubmit} variant="contained" > Submit</Button>
                            <p className={styles.error}>{hasError ? "Something went wrong" : ""}</p>
                            <p className={styles.success}>{isSaved ? "Review submitted successfully" : ""}</p>
                        </div>
                    </div>

                </div>
                <Footer />
            </div>
    );
}