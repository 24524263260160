import { useEffect, useState } from "react";
import styles from "./City.module.css";
import { Button, TextField } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import { API_HOST_URL } from "../Constant/Constant";
import loadingImgae from "../loading.gif";
import mainStyles from "../Main.module.css";


export default function City() {


    const [cityName, setCityName] = useState('');
    const [cityDescription, setCityDescription] = useState('');
    const [isSaved, setIsSaved] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [states, setStates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [stateId, setStateId] = useState('');



    useEffect(() => {
        const token = localStorage.getItem('token');

        const headerList = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }


        fetch(API_HOST_URL + "/states/", { headers: headerList })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setIsLoading(false);
                setStates(res);
            });
    }, []);



    const onSuccessfulSave = () => {
        setIsSaved(true);
        setIsLoading(false);
        setHasError(false);
    }

    const onFailedSave = () => {
        setIsLoading(false);
        setIsSaved(false);
        setHasError(true);
    }

    const resetForm = () => {
        setCityName('');
        setCityDescription('');
        setStateId('');
    }

    const validateForm = () => {
        if (cityName === "" || stateId === "") {
            return false;
        }
        return true;
    }

    const handleSubmit = (e) => {
        if (validateForm()) {
            const token = localStorage.getItem('token');
            setIsLoading(true);

            const headerList = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }

            e.preventDefault();
            const city = { cityName, cityDescription, stateId };

            fetch(API_HOST_URL + "/cities/", {
                method: "POST",
                headers: headerList,
                body: JSON.stringify(city)
            }).then((res) => {
                onSuccessfulSave();
                resetForm();
            }).catch((error) => {
                onFailedSave();
            });
        }
        else {
            onFailedSave();
        }


    }


    return (
        isLoading ? <div className={mainStyles.loadingDiv}><img src={loadingImgae} alt="loading" /></div> :
            <div className={styles.mainDiv}>
                <header className={mainStyles.header}>Add City</header>
                <form onSubmit={handleSubmit}>
                    <div className={styles.ddlContainer}>
                        <select className={styles.ddl} onChange={(e) => setStateId(e.target.value)} >
                            <option> Select State </option>
                            {states.map((state) => (
                                <option key={state.stateId} value={state.stateId}>{state.stateName}</option>
                            ))}

                        </select>
                    </div>

                    <div className={styles.componentContainer}>
                        <TextField className={styles.component} variant="standard" onChange={(e) => setCityName(e.target.value)} label="Enter City Name" />
                    </div>

                    <div className={styles.componentContainer}>
                        <TextField className={styles.component} onChange={(e) => setCityDescription(e.target.value)} label="Enter City Description" multiline rows={3} />
                    </div>
                    <div className={styles.componentContainer}>
                        <Button className={styles.saveBtn} type="submit" variant="contained" startIcon={<SaveIcon />}> Save City</Button>
                    </div>

                    <div className={styles.componentContainer}>
                        {isSaved && <div><p className={mainStyles.success}>City Saved Successfully</p></div>}
                        {hasError && <div><p className={mainStyles.success}>Some error occurred, please try again later.</p></div>}
                    </div>
                </form>
            </div >
    )
}

