
import { useEffect, useState } from "react"
import styles from "./booking.module.css"
import { API_HOST_URL } from "../Constant/Constant";


export default function CarWashBookingDetail({ bookingId }) {
    // let bookingId = (new URLSearchParams(window.location.search)).get("bookingId")
    const [booking, setBooking] = useState();


    useEffect(() => {
        fetch(API_HOST_URL + "/bookings/carWash/" + bookingId)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setBooking(res);
            });
    }, [bookingId]);

    return (
        <div>
            <header className={styles.header}>Car Wash Booking Detail</header>
            {booking && <div className={styles.bookingDeailContainer}>
                <div className={styles.bookingRow}><div className={styles.bookingKey}>Booking #:</div><div className={styles.bookingValue}>{booking.bookingNumber} </div></div>
                <div className={styles.bookingRow}><div className={styles.bookingKey}>Customer Name:</div><div className={styles.bookingValue}>{booking.customerFirstName} {booking.customerLastName}</div></div>
                <div className={styles.bookingRow}><div className={styles.bookingKey}>Model:</div><div className={styles.bookingValue}>{booking.brand} {booking.carModel}</div></div>
                <div className={styles.bookingRow}><div className={styles.bookingKey}>Service Date:</div><div className={styles.bookingValue}>{booking.scheduledAt}</div></div>
                <div className={styles.bookingRow}><div className={styles.bookingKey}>Reg. Number:</div><div className={styles.bookingValue}>{booking.registrationNumber}</div></div>
                <div className={styles.bookingRow}><div className={styles.bookingKey}>Status:</div><div className={styles.bookingValue}>BOOKED</div></div>
                <div className={styles.bookingRow}><div className={styles.bookingKey}>Email:</div><div className={styles.bookingValue}>{booking.email}</div></div>
                <div className={styles.bookingRow}><div className={styles.bookingKey}>Address:</div><div className={styles.bookingValue}>{booking.street}</div></div>
                <div className={styles.bookingRow}><div className={styles.bookingKey}>City:</div><div className={styles.bookingValue}>{booking.city}</div></div>
                <div className={styles.bookingRow}><div className={styles.bookingKey}>Color:</div><div className={styles.bookingValue}>{booking.color}</div></div>
                <div className={styles.bookingRow}><div className={styles.bookingKey}>Mobile:</div><div className={styles.bookingValue}>{booking.phoneNumber}</div></div>


            </div>}
        </div>
    )
}