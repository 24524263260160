import { useEffect, useState } from "react"
import styles from "./CarWash.module.css"
import { API_HOST_URL } from "../Constant/Constant";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import SaveIcon from '@mui/icons-material/Save';
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";


export default function CarWashStatusUpdate() {
    const [bookings, setBookings] = useState([]);
    const [workers, setWorkers] = useState([]);
    const [carWash, setCarWash] = useState();
    const [isSaved, setIsSaved] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [workerId, setWorkerId] = useState();
    const [remarks, setRemarks] = useState();
    const [completedAt, setCompletedAt] = useState();
    const [cancelledAt, setCancelledAt] = useState();
    const [totalCost, setTotalCost] = useState();
    const [washingStatusList, setWashingStatusList] = useState([]);
    const [washingStatus, setWashingStatus] = useState();
    const [address, setAddress] = useState();
    const [bookingId, setBookingId] = useState();
    const [paymentStatus, setPaymentStatus] = useState();
    const [paymentStatusList, setPaymentStatusList] = useState();
    const [isLoading, setIsLoading] = useState(false);


    const fetchInitialData = () => {
        const getCarWashBookingIdList = axios.get(API_HOST_URL + "/bookings/bookingNumber/carWash");
        const getWorkers = axios.get(API_HOST_URL + "/workers/");
        const getCarWashStatusList = axios.get(API_HOST_URL + "/carWash/status");
        const getPaymentStatusList = axios.get(API_HOST_URL + "/bills/statusList");

        axios.all([getCarWashBookingIdList, getWorkers, getCarWashStatusList, getPaymentStatusList]).then(
            axios.spread((...allData) => {
                setBookings(allData[0].data);
                setWorkers(allData[1].data);
                setWashingStatusList(allData[2].data);
                setPaymentStatusList(allData[3].data);
            })
        )
    }

    useEffect(() => {
        fetchInitialData();
    }, []);


    const validateForm = () => {

        if (washingStatus !== undefined && bookingId !== undefined
            && ((washingStatus === "COMPLETED" && completedAt !== undefined && totalCost > 0.0 && workerId !== undefined)
                || (washingStatus === "CANCELLED" && cancelledAt !== undefined && totalCost === 0.0)))
            return true;

        return
    }



    const loadCarWash = (id) => {
        setBookingId(id);
        fetch(API_HOST_URL + "/carWash/" + id)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setCarWash(res);
            });
    }

    const onSuccessfulSave = () => {
        setIsSaved(true);
        setIsLoading(false);
        setHasError(false);
    }

    const onFailedSave = () => {
        setIsLoading(false);
        setIsSaved(false);
        setHasError(true);
    }

    const resetForm = () => {
        setBookingId('');
        setRemarks('');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (validateForm()) {

            const carWash = {
                totalCost, bookingId, washingStatus, workerId, remarks, completedAt, cancelledAt, address, paymentStatus
            };

            fetch(API_HOST_URL + "/carWash/", {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(carWash)
            }).then((res) => {
                onSuccessfulSave();
                resetForm();
            }).catch((error) => {
                onFailedSave();
            });
        }
        else {
            onFailedSave();
        }
    }

    return (
        isLoading ? <div className={mainStyles.loading}><img src={loadingImage} alt="loading" /></div> :
            <div>
                <h1 className={mainStyles.header}>Car Wash Status Update</h1>
                <form onSubmit={handleSubmit}>
                    <div className={styles.carWashContainerDiv}>

                        {bookings && <div className={styles.bookingDeailContainer}>

                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Booking #</div><div className={styles.bookingValue}><select className={styles.workerddl} onChange={(e) => loadCarWash(e.target.value)} >
                                <option> Select Booking Id </option>
                                {bookings.map((booking) => (
                                    <option key={booking.bookingId} value={booking.bookingId}>{booking.bookingNumber}</option>
                                ))}

                            </select></div></div>
                        </div>}

                        {carWash && <div className={styles.propContainer}>




                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Customer Name:</div><div className={styles.bookingValue}>{carWash.customerFullName} </div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Model:</div><div className={styles.bookingValue}>{carWash.carModel}</div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Color:</div><div className={styles.bookingValue}>{carWash.color}</div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Worker:</div><div className={styles.bookingValue}>
                                <select className={styles.workerddl} defaultValue={carWash.workerId} onChange={(e) => setWorkerId(e.target.value)} >
                                    <option> Select Worker </option>
                                    {workers.map((worker) => (
                                        <option key={worker.id} value={worker.id}>{worker.name}</option>
                                    ))}

                                </select>
                            </div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Washing Status:</div><div className={styles.bookingValue}>
                                <select className={styles.workerddl} defaultValue={carWash.status} onChange={(e) => setWashingStatus(e.target.value)} >
                                    <option> Select Status </option>
                                    {washingStatusList.map((status) => (
                                        <option key={status} value={status}>{status}</option>
                                    ))}

                                </select>
                            </div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Address:</div><div className={styles.bookingValue}><TextField multiline rows={2} onChange={(e) => setAddress(e.target.value)} label="Enter Address" defaultValue={carWash.street} /></div></div>
                            {washingStatus === "COMPLETED" && <div className={styles.bookingRow}><div className={styles.bookingKey}>Completed At:</div>
                                <div className={styles.bookingValue}> <label for="scheduleAt"></label>
                                    <input type="datetime-local" onChange={(e) => setCompletedAt(e.target.value)} id="completedAt" name="completeAt" /> </div></div>}
                            {washingStatus === "CANCELLED" && <div className={styles.bookingRow}><div className={styles.bookingKey}>Cancelled At:</div>
                                <div className={styles.bookingValue}> <label for="scheduleAt"></label>
                                    <input type="datetime-local" onChange={(e) => setCancelledAt(e.target.value)} id="cancelledAt" name="cancelledAt" /> </div></div>}

                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Total Cost:</div><div className={styles.bookingValue}>
                                <TextField onChange={(e) => setTotalCost(e.target.value)} label="Enter cost" defaultValue={0.0} />
                            </div></div>
                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Payment Status:</div><div className={styles.bookingValue}>
                                <select className={styles.workerddl} onChange={(e) => setPaymentStatus(e.target.value)} >
                                    <option> Select Status </option>
                                    {paymentStatusList.map((status) => (
                                        <option key={status} value={status}>{status}</option>
                                    ))}

                                </select>
                            </div></div>

                            <div className={styles.bookingRow}><div className={styles.bookingKey}>Remaks:</div><div className={styles.bookingValue}><TextField multiline rows={2} onChange={(e) => setRemarks(e.target.value)} label="Enter Remarks" defaultValue={carWash.remarks} /></div></div>

                            <div className={styles.component}> <Button type="submit" variant="contained" startIcon={<SaveIcon />}> Update Status</Button></div>
                            <br />
                            {hasError && <p className={mainStyles.error}>Please fill all the mandatory fields.</p>}
                            {isSaved && <p className={mainStyles.success}> Car Wash status updated successfully</p>}
                        </div>}
                    </div>
                </form>
            </div>
    )
}