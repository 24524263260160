import React from "react";
import { API_HOST_URL } from "../Constant/Constant";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { Button } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import mainStyles from "../Main.module.css";
import loadingImgae from "../loading.gif";
import styles from "./LoginPage.module.css";
import { HeaderPlain } from "../Header/HeaderPlain";
import { Footer } from "../Footer/Footer";

const ResetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Unable to reset password, please try again after sometime."
  );
  const [successMessage, setSuccessMessage] = useState(
    "Password reset successfully."
  );
  const [isSuccess, setIsSuccess] = useState(false);

  let resetToken = searchParams.get("resetToken");

  const onSuccessfulSave = () => {
    setIsLoading(false);
    setHasError(false);
    setIsSuccess(true);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setHasError(true);
    setIsSuccess(false);
  };

  const validatePassword = (password) => {
    if (password.length < 6) {
      setErrorMessage("Password must be at least 6 characters long.");
      onFailedSave();
      return false;
    }
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      onFailedSave();
      return false;
    }

    let isValid = true;
    if (!password.match(/[a-z]+/)) {
      isValid = false;
    }
    if (!password.match(/[A-Z]+/)) {
      isValid = false;
    }
    if (!password.match(/[0-9]+/)) {
      isValid = false;
    }

    if (!isValid) {
      setErrorMessage(
        "Password must contain at least one uppercase letter, one lowercase letter and one number."
      );
      onFailedSave();
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validatePassword(password)) {
      return;
    }

    setIsLoading(true);

    const resetPasswordObj = {
      resetToken,
      password,
    };

    fetch(API_HOST_URL + "/auth/resetPassword", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(resetPasswordObj),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          setSuccessMessage(res.message);
          onSuccessfulSave();
        } else {
          setErrorMessage(res.message);
          onFailedSave();
        }
      })
      .catch((error) => {
        onFailedSave();
      });
  };

  return isLoading ? (
    <div className={mainStyles.loadingContainer}>
      <img src={loadingImgae} alt="loading" />
    </div>
  ) : (
    <div>
      <HeaderPlain />
      <div>
        <div>
          <header className={mainStyles.header}>Reset Password</header>

          <div className={styles.passwordContainer}>
            <div className={mainStyles.passwordDiv}>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Password"
              />
              <div className={mainStyles.showPasswordIcon}>
                {showPassword ? (
                  <RemoveRedEyeIcon onClick={() => setShowPassword(false)} />
                ) : (
                  <VisibilityOffIcon onClick={() => setShowPassword(true)} />
                )}
              </div>
            </div>

            <div className={mainStyles.passwordDiv}>
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="password"
                name="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
                required
              />
              <div className={mainStyles.showPasswordIcon}>
                {showConfirmPassword ? (
                  <RemoveRedEyeIcon
                    onClick={() => setShowConfirmPassword(false)}
                  />
                ) : (
                  <VisibilityOffIcon
                    onClick={() => setShowConfirmPassword(true)}
                  />
                )}
              </div>
            </div>
            <div className={mainStyles.submitButtonDiv}>
              <Button
                className={mainStyles.submitButton}
                onClick={handleSubmit}
                type="submit"
              >
                Update
              </Button>
            </div>
            <div>
              {hasError && (
                <div className={mainStyles.errorMessage}>{errorMessage}</div>
              )}
              {isSuccess && (
                <div className={mainStyles.successMessage}>
                  {successMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPassword;
