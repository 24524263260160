import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Brand from "./components/Brand/Brand";
import Country from "./components/Country/Country";
import State from "./components/State/State";
import City from "./components/City/City";
import Customer from "./components/Customer/Customer";
import Operator from "./components/Operator/Operator";
import Worker from "./components/Worker/Worker";
import BikeModel from "./components/BikeModel/BikeModel";
import NewBike from "./components/NewBike/NewBike";
import NewBikeDetail from "./components/NewBike/NewBikeDetail";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UsedBike from "./components/UsedBike/UsedBike";
import UsedBikeDetail from "./components/UsedBike/UsedBikeDetail";
import Workshop from "./components/Workshop/Workshop";
import BikeServiceBooking from "./components/Booking/BikeServiceBooking";
import CarModel from "./components/CarModel/CarModel";
import CarWashBooking from "./components/Booking/CarWashBooking";
import CarWashBookingList from "./components/Booking/CarWashBookingList";
import CarWashBookingDetail from "./components/Booking/CarWashBookingDetail";
import BikeServiceBookingDetail from "./components/Booking/BikeServiceBookingDetail";
import BikeServiceBookingList from "./components/Booking/BikeServiceBookingList";
import LandingPage from "./components/LandingPage/LandingPage";
import LoginPage from "./components/Login/LoginPage";
import HomePage from "./components/HomePage/HomePage";
import Admin from "./components/Admin/Admin";
import CarWash from "./components/CarWash/CarWash";
import CarWashStatusUpdate from "./components/CarWash/CarWashStatusUpdate";
import BikeServicing from "./components/BikeServicing/BikeServicing";
import BikeServicingStatusUpdate from "./components/BikeServicing/BikeServicingStatusUpdate";
import UsedBikeListShort from "./components/UsedBike/UsedBikeListShort";
import NewBikeListShort from "./components/NewBike/NewBikeListShort.jsx";
import WorkshopList from "./components/Workshop/WorkshopList";
import ReviewCarWash from "./components/Review/ReviewCarWash.jsx";
import ReviewBikeServicing from "./components/Review/ReviewBikeServicing.jsx";
import ContactForm from "./components/FooterPages/Contact.jsx";
import AboutUs from "./components/FooterPages/About.jsx";
import ResetPassword from "./components/Login/ResetPassword.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="brand" element={<Brand />} />
        <Route path="country" element={<Country />} />
        <Route path="state" element={<State />} />
        <Route path="city" element={<City />} />
        <Route path="customer" element={<Customer />} />
        <Route path="operator" element={<Operator />} />
        <Route path="worker" element={<Worker />} />
        <Route path="bikeModel" element={<BikeModel />} />
        <Route path="newBike" element={<NewBike />} />
        <Route path="home" element={<HomePage />} />
        <Route path="landingPage" element={<LandingPage />} />

        <Route path="newBikeDetail" element={<NewBikeDetail />} />
        <Route path="usedBike" element={<UsedBike />} />
        <Route path="usedBikeDetail" element={<UsedBikeDetail />} />
        <Route path="workshop" element={<Workshop />} />
        <Route path="carModel" element={<CarModel />} />
        <Route path="bikeServiceBooking" element={<BikeServiceBooking />} />
        <Route path="carWashBooking" element={<CarWashBooking />} />
        <Route path="carWashBookingList" element={<CarWashBookingList />} />
        <Route path="carWashBookingDetail" element={<CarWashBookingDetail />} />
        <Route
          path="bikeServiceBookingList"
          element={<BikeServiceBookingList />}
        />
        <Route
          path="bikeServiceBookingDetail"
          element={<BikeServiceBookingDetail />}
        />
        <Route path="admin" element={<Admin />} />
        <Route path="carWash" element={<CarWash />} />
        <Route path="health" element={<div>Health Check</div>} />
        <Route path="carWashStatusUpdate" element={<CarWashStatusUpdate />} />
        <Route path="bikeServicing" element={<BikeServicing />} />
        <Route
          path="bikeServicingStatusUpdate"
          element={<BikeServicingStatusUpdate />}
        />
        <Route path="newBikeListShort" element={<NewBikeListShort />} />
        <Route path="usedBikeListShort" element={<UsedBikeListShort />} />
        <Route path="workshopList" element={<WorkshopList />} />
        <Route path="reviewCarWash" element={<ReviewCarWash />} />
        <Route path="reviewBikeServicing" element={<ReviewBikeServicing />} />
        <Route path="contact" element={<ContactForm />} />
        <Route path="aboutUs" element={<AboutUs />} />
        <Route path="resetPassword" element={<ResetPassword />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
