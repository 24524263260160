import { useEffect, useState } from "react";
import styles from "./UsedBike.module.css";
import { API_HOST_URL } from "../Constant/Constant";
import UsedBikeDetail from "./UsedBikeDetail";
import bikesImage from "./images/bikes.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import mainStyles from "../Main.module.css";
import AppPagination from "../Pagination/AppPagination";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function UsedBikeList({ modelId = "" }) {
  const [bikes, setBikes] = useState([]);
  const [showBikeDetail, setShowBikeDetail] = useState(false);
  const [showBikeList, setShowBikeList] = useState(true);
  const [bikeId, setBikeId] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [bikeNames, setBikeNames] = useState([]);
  const [model, setModel] = useState(modelId);

  useEffect(() => {
    let customerId = localStorage.getItem("userId");

    fetch(API_HOST_URL + "/bikeModels/names")
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setBikeNames(res.bikeModelNames);
      });

    fetch(
      API_HOST_URL +
        "/usedBikes?customer=" +
        customerId +
        "&model=" +
        model +
        "&page=" +
        page
    )
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setBikes(res.usedBikes);
        setTotalPages(res.totalPages);
      });
  }, [page, model]);

  const handleViewMore = (e, bike) => {
    setBikeId(bike);
    setShowBikeDetail(true);
    setShowBikeList(false);
  };

  const defaultProps = {
    options: bikeNames,
    getOptionLabel: (option) => option.name,
  };

  const handleBikeChange = (modelObj) => {
    setModel(modelObj.id);
  };

  return (
    <div>
      {showBikeList && (
        <div>
          {/* <header className={styles.header}>Used Bikes</header> */}
          <div className={styles.headingImageDiv}>
            <img src={bikesImage} alt="Bikes" className={styles.bigImage} />
          </div>

          <div className={styles.bikeListcontainer}>
            <div className={mainStyles.header}>Used Bikes</div>

            <div className={styles.mainContainer}>
              <Autocomplete
                onChange={(event, value) => handleBikeChange(value)}
                disablePortal
                {...defaultProps}
                sx={{
                  width: 300,
                  backgroundColor: "white",
                  borderRadius: "20px",
                  height: "40px",
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Search Bike" />
                )}
              />
            </div>
            {bikes.map((bike) => (
              <div className={styles.propContainer}>
                <div className={styles.bikeDetailContainer}>
                  <div className={styles.bikeImageDiv}>
                    <img
                      onClick={(e) => handleViewMore(e, bike.bikeId)}
                      className={styles.bikeImage}
                      src={bike.imageUrl}
                      alt="bike_image"
                    />
                  </div>
                  <div className={styles.bikeDetailDiv}>
                    <div className={styles.bikeDetail}>
                      <div className={styles.brand}>
                        {bike.brand} {bike.model}
                      </div>
                      <div className={styles.modelYear}>
                        {bike.modelYear} Model
                      </div>
                      <div className={styles.price}> {bike.price}/-</div>
                      <div>
                        <div className={styles.locationIcon}>
                          <LocationOnIcon fontSize="medium" />
                        </div>
                        <div className={styles.city}>{bike.sellerCity}</div>
                      </div>
                      <div className={styles.linkContainer}>
                        {" "}
                        <span
                          onClick={(e) => handleViewMore(e, bike.bikeId)}
                          className={styles.link}
                        >
                          View More{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <AppPagination setPage={setPage} totalPages={totalPages} />
          </div>
        </div>
      )}
      {showBikeDetail && <UsedBikeDetail bikeId={bikeId} />}
    </div>
  );
}
