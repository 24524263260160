import { useEffect, useState } from "react";
import styles from "./NewBike.module.css";
import { Button, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { API_HOST_URL } from "../Constant/Constant";
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";

export default function UpdateNewBike({ bikeId }) {
  const [isSaved, setIsSaved] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  //States for form fields
  const [summary, setSummary] = useState();
  const [mileage, setMileage] = useState();
  const [engineCapacity, setEngineCapacity] = useState();
  const [exShowroomPrice, setExShowroomPrice] = useState();
  const [variant, setVariant] = useState();
  const [height, setHeight] = useState();
  const [length, setLength] = useState();
  const [service1, setService1] = useState();
  const [service2, setService2] = useState();
  const [service3, setService3] = useState();
  const [service4, setService4] = useState();
  const [abs, setAbs] = useState();
  const [power, setPower] = useState();
  const [torque, setTorque] = useState();
  const [fuelCapacity, setFuelCapacity] = useState();
  const [launchDate, setLaunchDate] = useState();

  const [topViewImage, setTopViewImage] = useState();
  const [leftViewImage, setLeftViewImage] = useState();
  const [rightViewImage, setRightViewImage] = useState();
  const [frontViewImage, setFrontViewImage] = useState();
  const [backViewImage, setBackViewImage] = useState();
  const [topViewImageUploaded, setTopViewImageUploaded] = useState(false);
  const [leftViewImageUploaded, setLeftViewImageUploaded] = useState(false);
  const [rightViewImageUploaded, setRightViewImageUploaded] = useState(false);
  const [frontViewImageUploaded, setFrontViewImageUploaded] = useState(false);
  const [backViewImageUploaded, setBackViewImageUploaded] = useState(false);

  const [topViewImageUploadFail, setTopViewImageUploadFail] = useState(false);
  const [leftViewImageUploadFail, setLeftViewImageUploadFail] = useState(false);
  const [rightViewImageUploadFail, setRightViewImageUploadFail] =
    useState(false);
  const [frontViewImageUploadFail, setFrontViewImageUploadFail] =
    useState(false);
  const [backViewImageUploadFail, setBackViewImageUploadFail] = useState(false);

  const [bike, setBike] = useState();

  useEffect(() => {
    loadBike(bikeId);
  }, [bikeId, isSaved]);

  const loadBike = (bikeId) => {
    fetch(API_HOST_URL + "/newBikes/" + bikeId)
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setBike(res);
        setAttributes(res);
      });
  };

  const setAttributes = (res) => {
    setSummary(res.summary);
    setMileage(res.mileage);
    setEngineCapacity(res.engineCapacity);
    setExShowroomPrice(res.exShowroomPrice);
    setVariant(res.variant);
    setHeight(res.height);
    setLength(res.length);
    setService1(res.service1);
    setService2(res.service2);
    setService3(res.service3);
    setService4(res.service4);
    setAbs(res.abs);
    setPower(res.power);
    setTorque(res.torque);
    setFuelCapacity(res.fuelCapacity);
    setLaunchDate(res.launchDate);
    setFrontViewImage(res.frontViewImageUrl);
    setTopViewImage(res.topViewImageUrl);
    setLeftViewImage(res.leftViewImageUrl);
    setRightViewImage(res.rightViewImageUrl);
    setBackViewImage(res.backViewImageUrl);
  };

  const handleSubmitTop = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", topViewImage);
    formData.append("imageAngle", "TOP");
    let saved = saveImage(formData);
    if (saved) setTopViewImageUploaded(true);
    else setTopViewImageUploadFail(true);
  };

  const handleSubmitFront = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", frontViewImage);
    formData.append("imageAngle", "FRONT");
    let saved = saveImage(formData);
    if (saved) setFrontViewImageUploaded(true);
    else setFrontViewImageUploadFail(true);
  };

  const handleSubmitLeft = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", leftViewImage);
    formData.append("imageAngle", "LEFT");
    let saved = saveImage(formData);
    if (saved) setLeftViewImageUploaded(true);
    else setLeftViewImageUploadFail(true);
  };

  const handleSubmitRight = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", rightViewImage);
    formData.append("imageAngle", "RIGHT");
    let saved = saveImage(formData);
    if (saved) setRightViewImageUploaded(true);
    else setRightViewImageUploadFail(true);
  };

  const handleSubmitBack = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", backViewImage);
    formData.append("imageAngle", "BACK");
    let saved = saveImage(formData);
    if (saved) setBackViewImageUploaded(true);
    else setBackViewImageUploadFail(true);
  };

  const saveImage = (params) => {
    params.append("bikeId", bikeId);
    var saved = true;
    var ext = "";

    if (params.get("file") !== undefined)
      ext = params.get("file").name.split(".").pop().toLowerCase();
    if (
      !(
        ext === "jpeg" ||
        ext === "png" ||
        ext === "jpg" ||
        ext === "webp" ||
        ext === "avif"
      )
    ) {
      alert("Please upload a jpeg/png image file");
      return false;
    } else if (params.get("file") === undefined) {
      alert("Please select the image file to upload");
      return false;
    }

    fetch(API_HOST_URL + "/newBikes/images/", {
      method: "POST",
      body: params,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        updateImage(res);
        saved = true;
      })
      .catch((error) => {
        console.log(error);
        saved = false;
      });

    return saved;
  };

  const updateImage = (res) => {
    if (res.imageAngle === "TOP") {
      setTopViewImage(res.imageUrl);
    } else if (res.imageAngle === "FRONT") {
      setFrontViewImage(res.imageUrl);
    } else if (res.imageAngle === "LEFT") {
      setLeftViewImage(res.imageUrl);
    } else if (res.imageAngle === "RIGHT") {
      setRightViewImage(res.imageUrl);
    } else if (res.imageAngle === "BACK") {
      setBackViewImage(res.imageUrl);
    }
  };

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const newBike = {
      summary,
      mileage,
      engineCapacity,
      exShowroomPrice,
      variant,
      height,
      length,
      service1,
      service2,
      service3,
      service4,
      abs,
      power,
      torque,
      fuelCapacity,
      launchDate,
      bikeId,
    };

    fetch(API_HOST_URL + "/newBikes/", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newBike),
    })
      .then((res) => {
        window.scroll(0, 0);
        onSuccessfulSave();
      })
      .catch((error) => {
        onFailedSave();
      });
  };

  return (
    <div>
      <div className={mainStyles.loadingContainer}>
        {isLoading ? <img src={loadingImage} alt="loading" /> : null}
      </div>

      <div>
        {bike && (
          <div>
            <header className={mainStyles.header}>Update New Bike</header>

            <Divider>
              <Chip label="Model Information" size="small" />
            </Divider>
            <div className={styles.ddlContainer}>
              <span>
                {bike.model} - {bike.brand}, {bike.color}
              </span>
            </div>

            <div className={styles.componentContainer}>
              <TextField
                className={`${styles.component} ${styles.margin_top}`}
                onChange={(e) => setSummary(e.target.value)}
                label="Enter Summary"
                multiline
                rows={5}
                defaultValue={bike.summary}
              />
            </div>
            <Divider>
              <Chip label="Bike Specification" size="small" />
            </Divider>

            <div className={styles.componentContainer}>
              <TextField
                className={styles.component}
                variant="standard"
                onChange={(e) => setMileage(e.target.value)}
                label="Enter Milege"
                defaultValue={bike.mileage}
              />
              <div className={styles.componentContainer}>
                <TextField
                  className={styles.component}
                  variant="standard"
                  onChange={(e) => setEngineCapacity(e.target.value)}
                  label="Enter Engine Capacity"
                  defaultValue={bike.engineCapacity}
                />{" "}
              </div>
              <div className={styles.componentContainer}>
                <TextField
                  className={styles.component}
                  variant="standard"
                  onBlur={(e) => setExShowroomPrice(e.target.value)}
                  label="Ex Showroom Price"
                  defaultValue={bike.exShowroomPrice}
                />
              </div>
              <div className={styles.componentContainer}>
                <TextField
                  className={styles.component}
                  variant="standard"
                  onBlur={(e) => setVariant(e.target.value)}
                  label="Enter variant"
                  defaultValue={bike.variant}
                />
              </div>
              <div className={styles.componentContainer}></div>
              <TextField
                className={styles.component}
                variant="standard"
                onBlur={(e) => setHeight(e.target.value)}
                label="Enter Height"
                defaultValue={bike.height}
              />
            </div>
            <div className={styles.componentContainer}>
              <TextField
                className={styles.component}
                variant="standard"
                onBlur={(e) => setLength(e.target.value)}
                label="Enter Length"
                defaultValue={bike.length}
              />
            </div>
            <div className={styles.componentContainer}>
              <TextField
                className={styles.component}
                variant="standard"
                onBlur={(e) => setService1(e.target.value)}
                label="Service 1"
                defaultValue={bike.service1}
              />
            </div>
            <div className={styles.componentContainer}>
              <TextField
                className={styles.component}
                variant="standard"
                onBlur={(e) => setService2(e.target.value)}
                label="Service 2"
                defaultValue={bike.service2}
              />
            </div>
            <div className={styles.componentContainer}>
              <TextField
                className={styles.component}
                variant="standard"
                onBlur={(e) => setService3(e.target.value)}
                label="Service 3"
                defaultValue={bike.service3}
              />
            </div>
            <div className={styles.componentContainer}>
              <TextField
                className={styles.component}
                variant="standard"
                onBlur={(e) => setService4(e.target.value)}
                label="Service 4"
                defaultValue={bike.service4}
              />
            </div>
            <div className={styles.componentContainer}>
              <TextField
                className={styles.component}
                variant="standard"
                onBlur={(e) => setAbs(e.target.value)}
                label="Enter Abs"
                defaultValue={bike.abs}
              />
            </div>
            <div className={styles.componentContainer}>
              <TextField
                className={styles.component}
                variant="standard"
                onBlur={(e) => setPower(e.target.value)}
                label="Enter Power"
                defaultValue={bike.power}
              />
            </div>
            <div className={styles.componentContainer}>
              <TextField
                className={styles.component}
                variant="standard"
                onBlur={(e) => setTorque(e.target.value)}
                label="Enter Torque"
                defaultValue={bike.torque}
              />
            </div>
            <div className={styles.componentContainer}>
              <TextField
                className={styles.component}
                variant="standard"
                onBlur={(e) => setFuelCapacity(e.target.value)}
                label="Enter fuel capacity"
                defaultValue={bike.fuelCapacity}
              />
            </div>
            <div className={styles.componentContainer}>
              <label className={styles.component} for="launchDate">
                Launch Date{" "}
              </label>
              <input
                type="date"
                onBlur={(e) => setLaunchDate(e.target.value)}
                id="launchDate"
                name="launchDate"
                defaultValue={bike.launchDate}
              />{" "}
            </div>
            <div className={styles.uploadFormContainer}>
              <img
                className={styles.updateImage}
                src={topViewImage}
                alt="top"
              />
              <form onSubmit={handleSubmitTop} encType="multipart/form-data">
                <input
                  type="file"
                  onChange={(e) => setTopViewImage(e.target.files[0])}
                />
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  {" "}
                  Upload{" "}
                </Button>
                {topViewImageUploaded && (
                  <p className="inline"> Image uploaded Successfully</p>
                )}
                {topViewImageUploadFail && (
                  <p className="inline"> Image upload Failed</p>
                )}
              </form>{" "}
            </div>

            <Divider>
              <Chip label="Image for Bike Front view" size="small" />
            </Divider>

            <div className={styles.uploadFormContainer}>
              <img
                className={styles.updateImage}
                src={frontViewImage}
                alt="top"
              />
              <form onSubmit={handleSubmitFront} encType="multipart/form-data">
                <input
                  type="file"
                  onChange={(e) => setFrontViewImage(e.target.files[0])}
                />
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  {" "}
                  Upload{" "}
                </Button>
                {frontViewImageUploaded && (
                  <p className="inline"> Image uploaded Successfully</p>
                )}
                {frontViewImageUploadFail && (
                  <p className="inline"> Image upload Failed</p>
                )}
              </form>{" "}
            </div>

            <Divider>
              <Chip label="Image for Bike Left view" size="small" />
            </Divider>
            <div className={styles.uploadFormContainer}>
              <img
                className={styles.updateImage}
                src={leftViewImage}
                alt="top"
              />
              <form onSubmit={handleSubmitLeft} encType="multipart/form-data">
                <input
                  type="file"
                  onChange={(e) => setLeftViewImage(e.target.files[0])}
                />
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  {" "}
                  Upload{" "}
                </Button>
                {leftViewImageUploaded && (
                  <p className="inline"> Image uploaded Successfully</p>
                )}
                {leftViewImageUploadFail && (
                  <p className="inline"> Image upload Failed</p>
                )}
              </form>
            </div>
            <Divider>
              <Chip label="Image for Bike Right view" size="small" />
            </Divider>

            <div className={styles.uploadFormContainer}>
              <img
                className={styles.updateImage}
                src={rightViewImage}
                alt="top"
              />
              <form onSubmit={handleSubmitRight} encType="multipart/form-data">
                <input
                  type="file"
                  onChange={(e) => setRightViewImage(e.target.files[0])}
                />
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  {" "}
                  Upload{" "}
                </Button>
                {rightViewImageUploaded && (
                  <p className="inline"> Image uploaded Successfully</p>
                )}
                {rightViewImageUploadFail && (
                  <p className="inline"> Image upload Failed</p>
                )}
              </form>{" "}
            </div>

            <Divider>
              <Chip label="Image for Bike Back view" size="small" />
            </Divider>

            <div className={styles.uploadFormContainer}>
              <img
                className={styles.updateImage}
                src={backViewImage}
                alt="top"
              />
              <form onSubmit={handleSubmitBack} encType="multipart/form-data">
                <input
                  type="file"
                  onChange={(e) => setBackViewImage(e.target.files[0])}
                />
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  {" "}
                  Upload{" "}
                </Button>
                {backViewImageUploaded && (
                  <p className="inline"> Image uploaded Successfully</p>
                )}
                {backViewImageUploadFail && (
                  <p className="inline"> Image upload Failed</p>
                )}
              </form>{" "}
            </div>

            <div className={styles.componentContainer}>
              <Button
                onClick={handleSubmit}
                className={styles.saveBtn}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                {" "}
                Update
              </Button>{" "}
            </div>
            <div className={styles.componentContainer}>
              {isSaved && (
                <div>
                  <p className={mainStyles.success}>
                    Bike Updated Successfully
                  </p>
                </div>
              )}
              {hasError && (
                <div>
                  <p className={mainStyles.error}>
                    Some error occurred, please try later
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
