import React, { useState } from 'react';
import ModerateReview from './ModerateReview';
import AggregateWorkshopRating from './AggregateWorkshopRating';
import styles from './Review.module.css';

export default function ReviewActions() {
    const [showReviewActionsForm, setShowReviewActionsForm] = useState(true);
    const [showModerateReviewForm, setShowModerateReviewForm] = useState(false);
    const [showAggregateWorkshopRatingForm, setShowAggregateWorkshopRatingForm] = useState(false);

    const setterMap = {};
    setterMap['show_review_actions_form'] = setShowReviewActionsForm;
    setterMap['show_moderate_review_form'] = setShowModerateReviewForm;
    setterMap['show_aggregate_workshop_rating_form'] = setShowAggregateWorkshopRatingForm;

    const handleClick = (page) => {
        setterMap['show_review_actions_form'](false);
        setterMap['show_moderate_review_form'](false);
        setterMap['show_aggregate_workshop_rating_form'](false);
        setterMap[page](true);
    }

    return (
        <div>
            {showReviewActionsForm && <div>
                <div className={styles.bikeServiceContainer}>
                    <h1 className={styles.header}>Review Actions</h1>
                    <div ><button className={styles.actionBtn} onClick={handleClick.bind(this, "show_moderate_review_form")}>Moderate Review</button></div>
                    <div ><button className={styles.actionBtn} onClick={handleClick.bind(this, "show_aggregate_workshop_rating_form")} >Aggregate Rating</button></div>
                </div>
            </div>}
            {showModerateReviewForm && <ModerateReview />}
            {showAggregateWorkshopRatingForm && <AggregateWorkshopRating />}
        </div>
    );
}