import { useEffect, useState } from "react"
import styles from "./BikeServicing.module.css"
import { API_HOST_URL } from "../Constant/Constant";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import SaveIcon from '@mui/icons-material/Save';
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";


export default function BikeServicingComplete() {
    const [bookings, setBookings] = useState([]);
    const [bikeService, setBikeService] = useState();
    const [isSaved, setIsSaved] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [remarks, setRemarks] = useState();
    const [droppedAt, setDroppedAt] = useState();
    const [bookingId, setBookingId] = useState();
    const [totalCost, setTotalCost] = useState(0.0);

    const [isLoading, setIsLoading] = useState(false);



    const fetchInitialData = () => {
        const getCarWashBookingIdList = axios.get(API_HOST_URL + "/bookings/bookingNumber/bikeServicing");

        axios.all([getCarWashBookingIdList]).then(
            axios.spread((...allData) => {
                setBookings(allData[0].data);
            })
        )
    }

    useEffect(() => {
        fetchInitialData();
    }, []);


    const validateForm = () => {

        if (remarks === "" || droppedAt === undefined || droppedAt === "" || totalCost === 0.0) {
            return false;
        }

        return true;
    }



    const loadBikeServicing = (id) => {
        setBookingId(id);
        fetch(API_HOST_URL + "/bikeService/" + id)
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setBikeService(res);
                setRemarks(res.remarks);
                setDroppedAt(dayjs(new Date()));
            });
    }

    const onSuccessfulSave = () => {
        setIsSaved(true);
        setIsLoading(false);
        setHasError(false);
    }

    const onFailedSave = () => {
        setIsLoading(false);
        setIsSaved(false);
        setHasError(true);
    }

    const resetForm = () => {
        setRemarks('');
        setDroppedAt('');
        setBookingId('');
        setTotalCost(0.0);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (validateForm()) {

            const bikeServicing = {
                bookingId, droppedAt, remarks, totalCost
            };

            fetch(API_HOST_URL + "/bikeService/complete", {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(bikeServicing)
            }).then((res) => {
                onSuccessfulSave();
                resetForm();
            }).catch((error) => {
                onFailedSave();
            });
        }
        else {
            onFailedSave();
        }
    }

    return (
        isLoading ? <div className={mainStyles.loadingContainer}><img src={loadingImage} alt="loading" /></div> :
            <div>

                <div>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.carWashContainerDiv}>
                            <header className={mainStyles.header}>Bike Servicing - Complete</header>
                            {bookings && <div className={styles.bookingDeailContainer}>

                                <div className={styles.bookingRow}><div className={styles.bookingKey}>Booking #</div><div className={styles.bookingValue}><select className={styles.ddl} onChange={(e) => loadBikeServicing(e.target.value)} >
                                    <option> Select Booking Id </option>
                                    {bookings.map((booking) => (
                                        <option key={booking.bookingId} value={booking.bookingId}>{booking.bookingNumber}</option>
                                    ))}

                                </select></div></div>
                            </div>}

                            {bikeService && <div className={styles.container}>




                                <div className={styles.bookingRow}><div className={styles.bookingKey}>Customer Name:</div><div className={styles.bookingValue}>{bikeService.customerName} </div></div>
                                <div className={styles.bookingRow}><div className={styles.bookingKey}>Model:</div><div className={styles.bookingValue}>{bikeService.model}</div></div>
                                <div className={styles.bookingRow}><div className={styles.bookingKey}>Color:</div><div className={styles.bookingValue}>{bikeService.color}</div></div>
                                <div className={styles.bookingRow}><div className={styles.bookingKey}>Registration #:</div><div className={styles.bookingValue}>{bikeService.registrationNumber}</div></div>
                                <div className={styles.bookingRow}><div className={styles.bookingKey}>Total Cost:</div><div className={styles.bookingValue}>
                                    <TextField onChange={(e) => setTotalCost(e.target.value)} label="Enter cost" defaultValue={0.0} />
                                </div></div>
                                <div className={styles.bookingRow}><div className={styles.bookingKey}>Workshop:</div><div className={styles.bookingValue}>{bikeService.workshop}</div></div>
                                <div className={styles.bookingRow}><div className={styles.bookingKey}>Worker:</div><div className={styles.bookingValue}>{bikeService.worker}</div></div>
                                <div className={styles.bookingRow}><div className={styles.bookingKey}>Servicing Status:</div><div className={styles.bookingValue}>{bikeService.status}</div></div>
                                <div className={styles.bookingRow}><div className={styles.bookingKey}>Remaks:</div><div className={styles.bookingValue}><TextField multiline rows={2} onBlur={(e) => setRemarks(e.target.value)} label="Enter Remarks" defaultValue={bikeService.remarks} /></div></div>

                                <div className={styles.bookingRow}><div className={styles.bookingKey}>In workshop:</div><div className={styles.bookingValue}>

                                    <div className={styles.ddlContainer}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={[

                                                'MobileDateTimePicker'
                                            ]}>

                                                <DemoItem label="Completed Date/Time">
                                                    <MobileDateTimePicker className={styles.dateTime} onChange={(e) => setDroppedAt(e)} defaultValue={dayjs(new Date())} />
                                                </DemoItem>
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                </div>

                                <div className={styles.componentContainer}> <Button type="submit" variant="contained" endIcon={<SaveIcon />}> Update</Button></div>

                                {hasError && <p className={mainStyles.error}>Please fill all the mandatory fields.</p>}
                                {isSaved && <p className={mainStyles.success}> Bike Servicing status updated successfully</p>}
                            </div>}
                        </div>
                    </form>
                </div>
            </div>
    )
}