import { useState, useEffect } from "react";
import axios from "axios";
import { TextField, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import styles from "./UsedBike.module.css";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { API_HOST_URL } from "../Constant/Constant";
import mainStyles from "../Main.module.css";
import loadingImage from "../loading.gif";

export default function UpdateUsedBike({ bikeId }) {
  const [purchaseYear, setPurchaseYear] = useState();

  const [runningKms, setRunningKms] = useState();
  const [sellerName, setSellerName] = useState();
  const [sellerMobile, setSellerMobile] = useState();
  const [sellerAddress, setSellerAddress] = useState();
  const [sellerCityId, setSellerCityId] = useState();
  const [price, setPrice] = useState();
  const [registrationNumber, setRegistrationNumber] = useState();
  const [description, setDescription] = useState();

  const [isSaved, setIsSaved] = useState(false);
  const [bike, setBike] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(
    "Bike details updated successfully"
  );

  const [topViewImage, setTopViewImage] = useState();
  const [leftViewImage, setLeftViewImage] = useState();
  const [rightViewImage, setRightViewImage] = useState();
  const [frontViewImage, setFrontViewImage] = useState();
  const [backViewImage, setBackViewImage] = useState();
  const [topViewImageUploaded, setTopViewImageUploaded] = useState(false);
  const [leftViewImageUploaded, setLeftViewImageUploaded] = useState(false);
  const [rightViewImageUploaded, setRightViewImageUploaded] = useState(false);
  const [frontViewImageUploaded, setFrontViewImageUploaded] = useState(false);
  const [backViewImageUploaded, setBackViewImageUploaded] = useState(false);

  const [topViewImageUploadFail, setTopViewImageUploadFail] = useState(false);
  const [leftViewImageUploadFail, setLeftViewImageUploadFail] = useState(false);
  const [rightViewImageUploadFail, setRightViewImageUploadFail] =
    useState(false);
  const [frontViewImageUploadFail, setFrontViewImageUploadFail] =
    useState(false);
  const [backViewImageUploadFail, setBackViewImageUploadFail] = useState(false);

  const fetchInitialData = () => {
    const getBike = axios.get(API_HOST_URL + "/usedBikes/" + bikeId);

    axios.all([getBike]).then(
      axios.spread((...allData) => {
        setBike(allData[0].data);
        updateSetters(allData[0].data);
      })
    );
  };

  const updateSetters = (bike) => {
    setRunningKms(bike.runningKms);
    setSellerName(bike.sellerName);
    setSellerMobile(bike.sellerMobile);
    setSellerAddress(bike.sellerAddress);
    setPrice(bike.price);
    setRegistrationNumber(bike.registrationNumber);
    setDescription(bike.description);
    setBackViewImage(bike.backViewImageUrl);
    setFrontViewImage(bike.frontViewImageUrl);
    setLeftViewImage(bike.leftViewImageUrl);
    setRightViewImage(bike.rightViewImageUrl);
    setTopViewImage(bike.topViewImageUrl);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const handleSubmitTop = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", topViewImage);
    formData.append("imageAngle", "TOP");
    let saved = saveImage(formData);
    if (saved) setTopViewImageUploaded(true);
    else setTopViewImageUploadFail(true);
  };

  const handleSubmitFront = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", frontViewImage);
    formData.append("imageAngle", "FRONT");
    let saved = saveImage(formData);
    if (saved) setFrontViewImageUploaded(true);
    else setFrontViewImageUploadFail(true);
  };

  const handleSubmitLeft = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", leftViewImage);
    formData.append("imageAngle", "LEFT");
    let saved = saveImage(formData);
    if (saved) setLeftViewImageUploaded(true);
    else setLeftViewImageUploadFail(true);
  };

  const handleSubmitRight = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", rightViewImage);
    formData.append("imageAngle", "RIGHT");
    let saved = saveImage(formData);
    if (saved) setRightViewImageUploaded(true);
    else setRightViewImageUploadFail(true);
  };

  const handleSubmitBack = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", backViewImage);
    formData.append("imageAngle", "BACK");
    let saved = saveImage(formData);
    if (saved) setBackViewImageUploaded(true);
    else setBackViewImageUploadFail(true);
  };

  const saveImage = (params) => {
    params.append("bikeId", bikeId);
    var saved = true;

    fetch(API_HOST_URL + "/usedBikes/images/", {
      method: "POST",
      body: params,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        saved = true;
        updateImage(res);
      })
      .catch((error) => {
        console.log(error);
        saved = false;
      });

    return saved;
  };

  const updateImage = (res) => {
    if (res.imageAngle === "TOP") {
      setTopViewImage(res.imageUrl);
    } else if (res.imageAngle === "FRONT") {
      setFrontViewImage(res.imageUrl);
    } else if (res.imageAngle === "LEFT") {
      setLeftViewImage(res.imageUrl);
    } else if (res.imageAngle === "RIGHT") {
      setRightViewImage(res.imageUrl);
    } else if (res.imageAngle === "BACK") {
      setBackViewImage(res.imageUrl);
    }
  };

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    var customerId = localStorage.getItem("userId");
    const usedBike = {
      customerId,
      description,
      purchaseYear,
      runningKms,
      sellerName,
      sellerMobile,
      sellerAddress,
      sellerCityId,
      price,
      registrationNumber,
      bikeId,
    };

    fetch(API_HOST_URL + "/usedBikes/", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(usedBike),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setSuccessMessage(res.message);
        onSuccessfulSave();
        window.scroll(0, 0);
      })
      .catch((error) => {
        onFailedSave();
      });
  };

  return (
    <div>
      {bike && (
        <div>
          <header className={mainStyles.header}>Update Bike Details</header>

          <div className={styles.componentContainer}>
            <TextField
              className={styles.component}
              variant="standard"
              onChange={(e) => setSellerName(e.target.value)}
              label="Enter Seller Name"
              defaultValue={bike.sellerName}
            />
          </div>

          <div className={styles.componentContainer}>
            <TextField
              className={styles.component}
              type="phone"
              variant="standard"
              onChange={(e) => setSellerMobile(e.target.value)}
              label="Enter Seller Mobile"
              defaultValue={bike.sellerMobile}
            />
          </div>

          <div className={styles.componentContainer}>
            <TextField
              className={`${styles.component}`}
              onChange={(e) => setSellerAddress(e.target.value)}
              label="Enter Address"
              defaultValue={bike.sellerAddress}
              multiline
              rows={3}
            />
          </div>

          <div className={styles.ddlContainer}>
            <div>
              <span>City: </span>
              <span>{bike.sellerCity}</span>
            </div>
          </div>
          <div className={styles.ddlContainer}>
            <div>
              <span>Brand: </span>
              <span>
                {bike.brand} - {bike.model}, {bike.color}
              </span>
            </div>
          </div>
          <div className={styles.componentContainer}>
            <TextField
              className={styles.component}
              variant="standard"
              onChange={(e) => setRegistrationNumber(e.target.value)}
              label="Enter Registration Number"
              defaultValue={bike.registrationNumber}
            />
          </div>

          <div className={styles.componentContainer}>
            <TextField
              className={styles.component}
              type="number"
              variant="standard"
              onChange={(e) => setRunningKms(e.target.value)}
              label="Enter Running KMs"
              defaultValue={bike.runningKms}
            />
          </div>
          <div className={styles.componentContainer}>
            <TextField
              className={styles.component}
              type="number"
              variant="standard"
              onChange={(e) => setPrice(e.target.value)}
              label="Enter Selling Price"
              defaultValue={bike.price}
            />
          </div>

          <div className={styles.componentContainer}>
            <TextField
              className={`${styles.component}`}
              onChange={(e) => setDescription(e.target.value)}
              label="Enter remarks"
              defaultValue={bike.description}
              multiline
              rows={3}
            />
          </div>

          <Divider>
            <Chip label="Image for Bike Top view" size="small" />
          </Divider>
          <div className={styles.uploadFormContainer}>
            <img className={styles.updateImage} src={topViewImage} alt="top" />
            <form onSubmit={handleSubmitTop} encType="multipart/form-data">
              <input
                type="file"
                onChange={(e) => setTopViewImage(e.target.files[0])}
              />
              <Button
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                {" "}
                Upload{" "}
              </Button>
              {topViewImageUploaded && (
                <p className="inline"> Image uploaded Successfully</p>
              )}
              {topViewImageUploadFail && (
                <p className="inline"> Image upload Failed</p>
              )}
            </form>
          </div>
          <Divider>
            <Chip label="Image for Bike Front view" size="small" />
          </Divider>

          <div className={styles.uploadFormContainer}>
            <img
              className={styles.updateImage}
              src={frontViewImage}
              alt="top"
            />
            <form onSubmit={handleSubmitFront} encType="multipart/form-data">
              <input
                type="file"
                onChange={(e) => setFrontViewImage(e.target.files[0])}
              />
              <Button
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                {" "}
                Upload{" "}
              </Button>
              {frontViewImageUploaded && (
                <p className="inline"> Image uploaded Successfully</p>
              )}
              {frontViewImageUploadFail && (
                <p className="inline"> Image upload Failed</p>
              )}
            </form>
          </div>

          <Divider>
            <Chip label="Image for Bike Left view" size="small" />
          </Divider>
          <div className={styles.uploadFormContainer}>
            <img className={styles.updateImage} src={leftViewImage} alt="top" />
            <form onSubmit={handleSubmitLeft} encType="multipart/form-data">
              <input
                type="file"
                onChange={(e) => setLeftViewImage(e.target.files[0])}
              />
              <Button
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                {" "}
                Upload{" "}
              </Button>
              {leftViewImageUploaded && (
                <p className="inline"> Image uploaded Successfully</p>
              )}
              {leftViewImageUploadFail && (
                <p className="inline"> Image upload Failed</p>
              )}
            </form>
          </div>

          <Divider>
            <Chip label="Image for Bike Right view" size="small" />
          </Divider>
          <div className={styles.uploadFormContainer}>
            <img
              className={styles.updateImage}
              src={rightViewImage}
              alt="top"
            />
            <form onSubmit={handleSubmitRight} encType="multipart/form-data">
              <input
                type="file"
                onChange={(e) => setRightViewImage(e.target.files[0])}
              />
              <Button
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                {" "}
                Upload{" "}
              </Button>
              {rightViewImageUploaded && (
                <p className="inline"> Image uploaded Successfully</p>
              )}
              {rightViewImageUploadFail && (
                <p className="inline"> Image upload Failed</p>
              )}
            </form>
          </div>

          <Divider>
            <Chip label="Image for Bike Back view" size="small" />
          </Divider>

          <div className={styles.uploadFormContainer}>
            <img className={styles.updateImage} src={backViewImage} alt="top" />
            <form onSubmit={handleSubmitBack} encType="multipart/form-data">
              <input
                type="file"
                onChange={(e) => setBackViewImage(e.target.files[0])}
              />
              <Button
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                {" "}
                Upload{" "}
              </Button>
              {backViewImageUploaded && (
                <p className="inline"> Image uploaded Successfully</p>
              )}
              {backViewImageUploadFail && (
                <p className="inline"> Image upload Failed</p>
              )}
            </form>
          </div>

          <Divider></Divider>

          <div className={styles.componentContainer}>
            <Button
              onClick={handleSubmit}
              className={`${styles.component}`}
              type="submit"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              {" "}
              Save Bike
            </Button>
          </div>

          <div className={styles.componentContainer}>
            {isSaved && (
              <div>
                <p className={mainStyles.success}>{successMessage}</p>
              </div>
            )}
            {hasError && (
              <p className={mainStyles.error}>
                {" "}
                Note: All fields are mandatory except remarks.
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
